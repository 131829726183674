import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Bootstrap from 'Redux/Bootstrap';
import { App } from './App';
import './styles.scss';

Bootstrap()
  .then(() => App())
  .catch((error) => console.error(error));

// eslint-disable-next-line
// @ts-ignore - ReactDOM.createRoot is not yet in the types
const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<App />);

