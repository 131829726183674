import { TEpic } from 'Redux/Config';
import Actions from 'Redux/Actions';
import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { RequestApi } from 'utils/RequestApi';
import { ValidateInsuranceNumberDocument, ValidateInsuranceNumberMutation } from 'api/graphql';

export const registerWithInsuranceNumberEpic: TEpic = (action$) => action$.pipe(
  ofType(Actions.registerWithInsuranceNumberRequest.type),
  mergeMap((action) => {
    const { insuranceNumber = '', email, name = '' } = action.payload.user;
    return from(
      RequestApi.mutate<ValidateInsuranceNumberMutation>(ValidateInsuranceNumberDocument, { insuranceNumber, email })).pipe(
      mergeMap(({ data: response }) => {
        if (response?.validateInsuranceNumber) {
          return from([
            Actions.registerUserWithOnetimePasswordRequest({ name, email }),
            Actions.registerWithInsuranceNumberSuccess(),
            Actions.persistHealthInsuranceUserData({
              name,
              email,
              insuranceNumber,
              ...(action.payload.userMetaData || {}),
            })
          ]);
        }
        return of(Actions.registerWithInsuranceNumberFailure({ error: new Error('Invalid insurance number') }));
      }),
      catchError((error) => of(Actions.registerWithInsuranceNumberFailure({ error })))
    );
  })

);
