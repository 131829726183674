import Image1x from './anker-group.png';
import Image2x from './anker-group@2x.png';
import Image3x from './anker-group@3x.png';

export default {
  src: Image1x,
  srcSet: {
    '2x': Image2x,
    '3x': Image3x
  }
};
