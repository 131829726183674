import React, { FunctionComponent } from 'react';
import Style from './Button.style';
import { ButtonProps } from 'antd';
import { Link } from '@reach/router';

type TProps = ButtonProps & {
  to?: string;
};

export const Button: FunctionComponent<TProps> = ({ children, to, ...props }) => {
  if (to && to.includes('://')) {
    return (
      <a href={to}>
        <Style {...props}>{children}</Style>
      </a>
    );
  }

  if (to && props.type === 'link') {
    return (
      <Link to={to} title={`Gehe zur Seite: ${to}`}>
        <Style {...props}>{children}</Style>
      </Link>
    );
  }

  return <Style {...props}>{children}</Style>;
};
