import React, { FC, useState } from 'react';
import { Link } from 'View/Common';
import { Image } from '@missionme/mime_elements';

type THoverImageLinkProps = {
  image: any;
  hoverImage?: React.ReactNode;
  to?: string;
  href?: string | null;
  className?: string;
  alt?: string;
};
export const HoverImageLink: FC<THoverImageLinkProps> = ({ image, hoverImage = image, to, className, alt, href }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOverOut = () => setIsHovered((isHovered) => !isHovered);

  const displayImage = isHovered ? hoverImage : image;

  return (
    <div onMouseEnter={handleMouseOverOut} onMouseLeave={handleMouseOverOut}>
      <Link to={to} href={href ?? undefined}>
        <Image className={className} src={displayImage} alt={alt} />
      </Link>
    </div>
  );
};
