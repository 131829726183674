import React from 'react';
import { StripeSepaDebitPaymentMethod } from 'api/graphql';

interface Props {
  data: StripeSepaDebitPaymentMethod;
}

const SepaDebit = React.memo((props: Props) => {
  const { sepaDebit } = props.data;
  return (
    <>
      <div className="summary__label">SEPA / Lastschrift</div>
      <div className="summary__label">
        IBAN: **** **** **** {sepaDebit.last4}
      </div>
    </>
  );
  /* render using props */
});

export default SepaDebit;
