import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import { useContext } from 'react';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';

const Subheader: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;
  const [store] = useContext(Store);
  const { billingDetails, isBillingDetailsDirty, stripeSetupIntent } = store;

  return (
    <div className="subheader__wrapper">
      <nav className="subheader">
        <Link
          className={`subheader__link ${
            path === ROUTES.BILLING_DETAILS ? 'subheader__link--active' : ''
          }`}
          to={ROUTES.BILLING_DETAILS}
        >
          1 Rechnungsdaten
        </Link>
        {billingDetails && !isBillingDetailsDirty ? (
          <Link
            className={`subheader__link ${
              path === ROUTES.PAYMENT_METHOD ? 'subheader__link--active' : ''
            }`}
            to={ROUTES.PAYMENT_METHOD}
          >
            2 Zahlungsart
          </Link>
        ) : (
          <span className="subheader__link">2 Zahlungsart</span>
        )}
        {isBillingDetailsDirty || !billingDetails || !stripeSetupIntent ? (
          <span className="subheader__link">3 Übersicht</span>
        ) : (
          <Link
            className={`subheader__link ${
              path === ROUTES.SUMMARY ? 'subheader__link--active' : ''
            }`}
            to={ROUTES.SUMMARY}
          >
            3 Übersicht
          </Link>
        )}
      </nav>
    </div>
  );
};

export default Subheader;
