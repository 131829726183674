import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { colors } from 'utils/colors';

export default styled.span `
  font-family: "Source Sans Pro";
  color: ${colors.deprecated.white.c500};
  font-size: ${asRem(16)};
  font-weight: 300;
  letter-spacing: ${asRem(0.15)};
  line-height: ${asRem(20)};
  
  button.ant-btn-link {
    font-size:${asRem(16)};
    letter-spacing: 0;
    span {
      color: ${colors.deprecated.white.c500};
      text-decoration: underline;
      &:after {
        content: none;
      }
    }
  }
  
  &.small {
    font-size: ${asRem(12)};

    button.ant-btn-link {
      font-size: ${asRem(12)};
      font-weight: 600;

    }
  }
`;
