import React, { FC } from 'react';
import { Paragraph } from 'View/Common';
import { TextBlockImg } from 'View/Component';

type TProps = {
  children?: React.ReactNode;
  image: string;
  className?: string;
  style?: React.CSSProperties;
  dataCyName?: string;
};

export const ImageParagraph: FC<TProps> = ({ children, image, dataCyName }) => {
  return (
    <TextBlockImg image={image} dataCyName={dataCyName}>
      <Paragraph>{children}</Paragraph>
    </TextBlockImg>
  );
};
