import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Header from 'components/Header';
import ImgFreddyShow from 'img/contact_page/freddy-zeigt.svg';
import StoreButtons from 'components/StoreButtons';

class Contact extends React.PureComponent<RouteComponentProps> {
  render() {
    const { path } = this.props;
    return (
      <>
        <Header path={path} />
        <main className="main contact">
          <div className="wrapper flex-column padding-top-100 margin-bottom-50">
            <h1 className="heading heading-1 margin-bottom-8">Kontakt</h1>
            <p className="paragraph paragraph__text-center margin-bottom-24">
              Du hast ein Anliegen? Schreib uns gerne, wir freuen uns auf Deine Nachricht!
            </p>
            <div className="box box__rounded">
              <div className="box__elem">
                <div className="margin-bottom-33 wrapper__box flex-column">
                  <p className="paragraph paragraph__text-center">Du erreichst uns unter der E-Mail:</p>
                  <a href="mailto:info@7schlaefer.app" className="link__cta" title="E-Mail senden an: info@7schlaefer.app">
                    info@7schlaefer.app
                  </a>
                </div>
                <div className="wrapper flex-column">
                  <p className="paragraph paragraph__text-center padding-bottom-15">Hier kannst du die App herunterladen:</p>
                  <StoreButtons />
                </div>
              </div>
            </div>
            <div className="wrapper flex-column contact__wrapper-illu">
              <img className="freddy-show" src={ImgFreddyShow} alt="7Schläfer Freddy zeigt auf Link" />
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Contact;
