import React, { FunctionComponent } from 'react';
import { Modal } from 'View/Component';
import { Headline, Paragraph } from 'View/Common';

interface IProps {
  open?: boolean;
  onClose?: () => void;
}

export const HealthRequirementsModal: FunctionComponent<IProps> = ({ open, onClose }) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={<Headline level={2}>Gesundheitsvorraussetzungen</Headline>}
    >
      <Paragraph>
        Die Teilnahme am 7Schläfer Präventionskurs erfolgt auf eigene Verantwortung.
        Ausschlusskriterien für eine Teilnahme sind schwere behandlungsbedürftige psychische
        Erkrankungen sowie behandlungsbedürftige Schlafstörungen.
      </Paragraph>
      <Paragraph>
        Der Kurs ist auf eine Teilnahme von Erwachsenen ab 18 Jahren ausgelegt, da ein gewisses Maß
        an Reflexionsfähigkeit und Selbstdisziplin erforderlich ist. Eine Teilnahme am Kurs
        „Erholsamen Schlaf finden“ erfolgt auf eigene Gefahr; eine Haftung wird nicht übernommen.
      </Paragraph>
      <Paragraph>
        Sollten während der Teilnahme schwere behandlungsbedürftige psychische Erkrankungen oder
        behandlungsbedürftige Schlafstörungen auftreten, sollte das Programm unterbrochen und
        lediglich nach Abklärung und in Begleitung von einer professionellen Unterstützung
        fortgesetzt werden.
      </Paragraph>
    </Modal>
  );
};
