export const COOPERATION_VOUCHER = {
  AOK_BAYERN: 'AOK_BAYERN',
  SALUS_BKK: 'SALUS_BKK',
  VIACTIV: 'VIACTIV',
} as const;

export type TCooperationVoucherConfig = Record<
  keyof typeof COOPERATION_VOUCHER | 'FALLBACK',
  {
    staging: string;
    production: string;
  }
>;

export const COOPERATION_VOUCHER_CONFIG: TCooperationVoucherConfig = {
  [COOPERATION_VOUCHER.AOK_BAYERN]: {
    staging: 'b4e318d1-c76d-4fde-8e1f-4aaa2146864a',
    production: '921c8f8a-416b-490e-b9a3-18b61c10c8d4',
  },
  [COOPERATION_VOUCHER.SALUS_BKK]: {
    staging: '9c6bf34c-38e0-4c1d-86e2-6494dd54538f',
    production: '10dcb816-6586-4ea7-bfac-d5327c31008a',
  },
  [COOPERATION_VOUCHER.VIACTIV]: {
    staging: '57ce209a-5a35-4175-981c-9a6260f665d4',
    production: '16d56459-d2a2-48d9-978a-0688c7b45913',
  },
  FALLBACK: {
    staging: 'fallback-staging',
    production: 'fallback-production',
  },
};
