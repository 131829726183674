import React, { FC } from 'react';
import { Col, Row } from '@missionme/mime_elements';

type TProps = {
  children?: React.ReactNode;
};


export const ImageTextRow: FC<TProps> = ({ children }) => {
  return (
    <Row className="image-paragraph-row">
      {React.Children.map(children, (child, index) => (
        <Col key={`col-element-${index}`} className="p-0" xs="12" md={4} lg="4">
          {child}
        </Col>
      ))}
    </Row>
  );
};
