export const arrayContains = (array: string[], searchFor: string[]) => {
    if (Array.isArray(searchFor)) {
        for(let i = 0, l = searchFor.length; i < l; i++) {
            if(array.includes(searchFor[i])) {
                return true;
            }
        }

        return false;
    }
    return array.includes(searchFor);
};
