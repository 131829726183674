import React from 'react';
import { ROUTES } from 'utils/typings/routes';
import { useLocation } from '@reach/router';
import Style from './PartnerLogo.style';

import xingPremiumLogo from 'img/b2b/xing-premium-logo-w.svg';
import xingProJobsLogo from 'img/b2b/xing-projobs-logo-w.svg';
import gothaerLogo from 'img/b2b/gothaer-logo-w.svg';

type LogoMapValue = {
  alt: string;
  src: string;
};

type LogoMap = Record<string, LogoMapValue>;

const logoMap: LogoMap = {
  [ROUTES.XING_PREMIUM]: {
    alt: 'Bild: XING Premium Logo',
    src: xingPremiumLogo
  },
  [ROUTES.XING_PRO_JOBS]: {
    alt: 'Bild: XING ProJobs Logo',
    src: xingProJobsLogo
  },
  [ROUTES.GOTHAER]: {
    alt: 'Bild: Gothaer Logo',
    src: gothaerLogo
  }
};

export const PartnerLogo = () => {
  const { pathname } = useLocation();
  const logo = logoMap[pathname];

  return <Style>{logo && <img src={logo.src} className="icon" alt={logo.alt} />}</Style>;
};
