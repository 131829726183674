import { asRem } from 'utils/asRem';

export const breakpoints = {
  xs: asRem(0),
  sm: asRem(576),
  md: asRem(768),
  lg: asRem(992),
  xl: asRem(1200),
  xxl: asRem(1600)
};

export const mediaQueries = {
  xs: `@media screen and (max-width: ${breakpoints.sm})`,
  sm: `@media screen and (min-width: ${breakpoints.sm})`,
  md: `@media screen and (min-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.lg})`,
  xl: `@media screen and (min-width: ${breakpoints.xl})`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl})`
};
