import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import Header from 'components/Header';
import HowToContinueDesktop from 'components/HowToContinueDesktop';
import HowToContinueMobile from 'components/HowToContinueMobile';

import steps1 from 'img/icon_01.svg';
import steps2 from 'img/icon_02.svg';
import steps3 from 'img/icon_03.svg';
import steps4 from 'img/icon_04.svg';

//////////////////////////////////////////////////////////////////////////////////////

const dataDesktop = {
  mainHeadline: 'Fast geschafft …',
  mainSubline: 'Der Kauf wurde abgeschlossen und du hast einen Freischaltlink per E-Mail bekommen.',
  headline: "Wie geht's jetzt weiter?",
  items: [
    {
      text: 'Der Kauf wurde abgeschlossen und du hast einen Freischaltlink per E-Mail bekommen.',
      icon: steps1,
    },
    {
      text: 'Lade die App aus dem Appstore und registriere dich, falls du es nicht bereits hast.',
      icon: steps2,
    },
    {
      text: 'Öffne den Freischalt-Link aus der E-Mail auf deinem Mobiltelefon.',
      icon: steps3,
    },
    {
      text: 'Alle Inhalte des 7Schläfer Programms werden in der App für dich freigeschaltet.',
      icon: steps4,
    },
  ],
  contactMail: 'info@7schlaefer.app',
};

const dataMobile = {
  mainHeadline: 'Fast geschafft …',
  mainSubline: 'Der Kauf wurde abgeschlossen und du hast einen Freischaltlink per E-Mail bekommen.',
  textStep1: 'Lade die App herunter und registriere dich, falls du es nicht bereits hast.',
  textStep2: 'Öffne diesen Freischalt-Link. Denselben haben wir dir ebenfalls per E-Mail geschickt.',
  textStep3: 'Alle Inhalte sind nun in der App für dich freigeschaltet. Bei Fragen melde dich gerne!',
};

//////////////////////////////////////////////////////////////////////////////////////

const UnkownUserBuy: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;

  return (
    <>
      <Header path={path} />
      <BrowserView>
        <HowToContinueDesktop data={dataDesktop} />
      </BrowserView>
      <MobileView>
        <HowToContinueMobile data={dataMobile} hasPurchaseToken />
      </MobileView>
    </>
  );
};

export default UnkownUserBuy;
