import { IUserState } from 'Redux/Slices/User/UserSlice';
import { Draft, PayloadAction } from '@reduxjs/toolkit';

export const resendOnetimePasswordRequest = (state: Draft<IUserState>, { type }: PayloadAction) => {
  state.openRequests = [ ...state.openRequests, type ];
  state.failedRequests = state.failedRequests.filter((actionType) => actionType !== 'user/resendOnetimePasswordFailure');
  state.updatedAt = Date.now();
};

export const resendOnetimePasswordSuccess = (state: Draft<IUserState>) => {
  state.updatedAt = Date.now();
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'user/resendOnetimePasswordRequest');
};

export const resendOnetimePasswordFailure = (state: Draft<IUserState>, { type }: PayloadAction<{ error: any }>) => {
  state.updatedAt = Date.now();
  state.failedRequests = [ ...state.failedRequests, type ];
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'user/resendOnetimePasswordRequest');
};
