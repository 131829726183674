import React from 'react';
import { PartnerLogo } from 'View/Component';
import { RouteComponentProps } from '@reach/router';

interface Props {
  // eslint-disable-next-line no-unused-vars
  refClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  data: {
    headlinePartOne: string;
    headlinePartTwo: string;
    imgClassName: string;
    altText: string;
    subline: string;
  };
}

type IProps = RouteComponentProps & Props;

const IntroB2B: React.FC<IProps> = (props) => {
  const { imgClassName, altText, headlinePartOne, headlinePartTwo, subline } =
    props.data;

  return (
    <div className="intro_b2b intro_b2b__bgr-stars">
      <div className="wrapper padding-top-30-100">
        <section className="intro_b2b__section">
          <div className={imgClassName}>
            <span className="visuallyhidden">{altText}</span>
          </div>
          <div className="intro_b2b__cta-container">
            <h1 className="heading heading-1-b2b">
              {headlinePartOne}
              <br />
              {headlinePartTwo}
            </h1>
            <p className="intro_b2b__paragraph">{subline}</p>
            <a
              className="intro_b2b__link-cta"
              href=""
              onClick={(e) => props.refClick(e)}
              rel="noopener noreferrer"
              title="Jetzt Starten"
            >
              JETZT STARTEN
            </a>
          </div>
        </section>
      </div>
      <div className="xing-logo-pos"></div>
      <PartnerLogo />
    </div>
  );
};

export default IntroB2B;
