import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { configureStore, Action, Tuple } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { rootReducer, TRootState } from './Reducers';
import { createEpicMiddleware } from 'redux-observable';
import Actions, { TActions } from 'Redux/Actions';
import { Observable } from 'rxjs';
import { rootEpics } from './Epics';

const persistConfig = {
  key: 'schlafen-website',
  storage,
  debug: true,
  whitelist: []
};

const epicMiddleware = createEpicMiddleware<Action, Action, TRootState, TActions>({
  dependencies: {
    ...Actions,
  },
});
const middleware = [ epicMiddleware ];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  // @ts-ignore - logger is not a default export
  middleware.push(logger);
}

export const Store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
// @ts-ignore
  middleware: () => new Tuple(...middleware),
});

export let Persistor: any;

export const rehydrateStore = (storeToHydrate: any) =>
  new Promise<void>((resolve) => {
    Persistor = persistStore(storeToHydrate, null, () => {
      resolve();
    });
  });

export type TEpic = (actions$: Observable<any>, state$: Observable<TRootState>, dependencies: TActions) => Observable<any>;

epicMiddleware.run(rootEpics);
