// importing the package.json exposes all the data to the bundle,
// so we use build-time to do the job
const VERSION = process.env.PACKAGE_VERSION;

const window = global.window || {
  location: { hostname: 'localhost' }
};

const platform = {
  isProduction: window.location.host === '7schlaefer.app',
  isStaging: window.location.host === 'staging.7schlaefer.app',
  isZPP: window.location.host === 'krankenkasse.7schlaefer.app',
  isDevelopment: window.location.hostname === 'localhost'
};

const environment = (() => {
  if (platform.isProduction) return 'production';
  if (platform.isStaging || platform.isZPP) return 'staging';
  if (platform.isDevelopment) return 'development';
  return 'test';
})();

module.exports = { VERSION, platform, environment };
