import styled from 'styled-components';
import logo from 'img/7schlaefer-logo.svg';

const SleepBrandLink = styled.div`
  .navbar-sleep-brand,
  .menu-story-container .navbar-transparent.navbar-down .navbar-sleep-brand {
    background-image: url(${logo});
  }

  .navbar-transparent .navbar-sleep-brand {
    background-image: url(${logo});
  }
`;

export default SleepBrandLink;
