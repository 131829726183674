import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { asRem } from 'utils/asRem';

export default styled.section `

  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: ${asRem(30)};
  max-width: ${asRem(1088)};
  width: 100%;
  padding: ${asRem(72)} ${asRem(16)};

  ${mediaQueries.md} {
    flex-direction: row;
    align-items: center;
    margin-bottom: ${asRem(50)};
    padding: ${asRem(48)} ${asRem(16)};
  }
  
`;
