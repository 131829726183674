import styled from 'styled-components';
import stars from 'img/stars.svg';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { asRem } from 'utils/asRem';

export default styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  background-image: url(${stars});
  padding-bottom: ${asRem(40)};

  ${mediaQueries.md} {
    padding-bottom: ${asRem(70)};
  }

`;
