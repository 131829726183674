import zppPhone from './phone-light.png';
import zppPhone2x from './phone-light@2x.png';
import zppPhone3x from './phone-light@3x.png';

export default {
  src: zppPhone,
  srcSet: {
    '2x': zppPhone2x,
    '3x': zppPhone3x
  }
};
