import React from 'react';

const StoreButtons = React.memo(() => {
  return (
    <div className="link__store-btn-wrapper">
      <a
        href="https://apps.apple.com/de/app/7schl%C3%A4fer-die-schlaf-app/id1520389863"
        className="link__store-btn link__appstore"
        title="7Schläfer im iTunes Store"
      />
      <a
        href="https://play.google.com/store/apps/details?id=com.greenhouse.schlafen&hl=gsw"
        className="link__store-btn link__playstore"
        title="7Schläfer im Playstore"
      />
    </div>
  );
});

export default StoreButtons;
