import React, { FunctionComponent } from 'react';
import { MenuEntries } from 'utils/Constants/MenuEntries';
import { Menu as MMMenu, MenuLink } from '@missionme/mime_elements';
import { SleepBrandLink } from '../SleepBrandLink/SleepBrandLink';

interface IProps {
  noNavigation?: boolean;
}

export const Menu: FunctionComponent<IProps> = ({ noNavigation }) => {
  return (
    <MMMenu brandLink={<SleepBrandLink />}>
      {!noNavigation &&
        MenuEntries.map(({ to, title }, index) => (
          <MenuLink key={`menuEntry${index}`} to={to}>
            {title}
          </MenuLink>
        ))}
    </MMMenu>
  );
};
