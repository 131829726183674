// Only publishable keys
// TODO: use process.env once the website can reference env vars at runtime
export default {
  gtmId: 'GTM-TF92NVT',
  staging: {
    STRIPE_PUBLIC_KEY:
      'pk_test_51GzyWbA6k5KBEwcnVMcNNPOl8pzTTgcgofbCTlgGUFBSHmC36Sx6wfsms4wRqRd5Stk9QSRb25ajeXWYbgVMaRNb00nual3C0Y'
  },
  production: {
    STRIPE_PUBLIC_KEY:
      'pk_live_51GzyWbA6k5KBEwcnTrBzdLneuU6KGzHrytnIXzw0I3rfSbmnBO62YD9EXqYHmsZKSw822uZGGKxO8buLgc5Ji8vm00QU5NjZVL'
  },
  BUGSNAG_KEY_WEBSITE: '3b568f4d381489f2e838beae619ad1c5'
};
