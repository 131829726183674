import { createContext } from 'react';

export type TInsuranceContainerContext = {
  b2bClientKey: string;
  activeView: 'FORM' | 'OTP' | 'SUCCESS' | 'ERROR';
};

export const INSURANCE_CONTAINER_VIEW = {
  FORM: 'FORM',
  OTP: 'OTP',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;

export const InsuranceContainerContext = createContext<TInsuranceContainerContext>({
  b2bClientKey: '',
  activeView: 'FORM',
});
