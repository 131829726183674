import React, { ReactNode } from 'react';

type TProps = {
  mailTo: string;
  children?: ReactNode;
  className?: string;
};

export const MailLink = ({ mailTo, children, className = '' }: TProps) => {
  return (
    <a
      href={`mailto:${mailTo}`}
      className={`link__markdown ${className}`}
      title={`E-Mail senden an: ${mailTo}`}
    >
      {children || mailTo}
    </a>
  );
};
