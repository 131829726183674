import styled from 'styled-components';
import { Modal } from 'antd';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';

export default styled(Modal)`
  &.ant-modal .ant-modal-content {
    background-color: ${colors.blue.c080};
    box-shadow: 0 0 ${asRem(30)} ${asRem(2)} ${colors.blue.c100};
    padding: ${asRem(24)} ${asRem(20)} ${asRem(24)} ${asRem(55)};
  }

  &.ant-modal .ant-modal-header {
    background-color: ${colors.blue.c080};
    text-align: center;
    padding-top: ${(props) => (props.title ? asRem(16) : 0)};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &.ant-modal .ant-modal-body {
    height: calc(100vh - ${asRem(320)});
    overflow: auto;
    padding-right: ${asRem(64)};

    &::-webkit-scrollbar-thumb {
      border: ${asRem(3)} solid transparent;
      border-radius: ${asRem(4)};
      background-color: ${colors.blue.c100};
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .content-area {
    padding-right: ${asRem(220)};

    ${mediaQueries.md} {
      padding-right: ${asRem(120)};
    }
  }

  .modal-image {
    max-width: ${asRem(180)};
    width: 100%;
    right: ${asRem(48)};
    bottom: ${asRem(48)};
    position: absolute;

    ${mediaQueries.md} {
      max-width: ${asRem(90)};
      right: ${asRem(24)};
      bottom: ${asRem(24)};
    }
  }

  .ant-modal-close {
    color: ${colors.deprecated.white.c500};
    width: ${asRem(32)};
    height: ${asRem(32)};

    &:hover {
      color: ${colors.deprecated.white.c500}80;
    }

    svg {
      width: ${asRem(24)};
      height: ${asRem(24)};
    }
  }

  .ScrollbarsCustom-TrackY {
    right: ${asRem(-56)} !important;
     top: ${asRem(30)} !important;
     height: calc(100% - ${asRem(30)}) !important;
    background-color: transparent !important;

    .ScrollbarsCustom-ThumbY {
      border-radius: ${asRem(6)} !important;
      background-color: ${colors.beige.c000} !important;
    }
  }
`;
