import { MouseEvent, useContext } from 'react';
import { SinglePaymentOffer, SubscriptionOffer } from 'api/graphql';
import { Store } from 'utils/store';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import { navigate } from '@reach/router';
import { ROUTES } from 'utils/typings/routes';

export const useSelectOffer = () => {
  const [, dispatch] = useContext(Store);

  return {
    selectOffer: (evt: MouseEvent, offer: SinglePaymentOffer | SubscriptionOffer) => {
      evt.preventDefault();
      dispatch({
        type: ACTION_TYPES.SET_SELECTED_OFFER,
        selectedOffer: offer
      });

      navigate(ROUTES.BILLING_DETAILS);
    }
  };
};
