import { useContext, useEffect } from 'react';
import { ACTION_TYPES } from './typings/actionTypes';

import { Store } from './store';

interface Props {
  isDirty: boolean;
}

const FormikContextHelper = (props: Props) => {
  const [store, dispatch] = useContext(Store);
  const { stripeSetupIntent } = store;
  const { isDirty } = props;
  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.UPDATE_IS_BILLING_DETAILS_DIRTY,
      isBillingDetailsDirty: isDirty
    });

    if (isDirty && stripeSetupIntent) {
      dispatch({
        type: ACTION_TYPES.UPDATE_STRIPE_SETUP_INTENT,
        stripeSetupIntent: undefined
      });
    }
  }, [isDirty, stripeSetupIntent, dispatch]);

  return null;
};

export default FormikContextHelper;
