import { applicationSlice, IApplicationState } from 'Redux/Slices/Application/ApplicationSlice';
import { userSlice,  IUserState } from 'Redux/Slices/User/UserSlice';
import { insuranceCooperationSlice, IInsuranceCooperationState } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';

import { combineReducers, PayloadAction } from '@reduxjs/toolkit';


export type TRootState = {
  [applicationSlice.name]: IApplicationState;
  [userSlice.name]: IUserState;
  [insuranceCooperationSlice.name]: IInsuranceCooperationState;
}

export const rootState = {
  [applicationSlice.name]: applicationSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [insuranceCooperationSlice.name]: insuranceCooperationSlice.reducer,
};

export const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combineReducers(rootState)(state, action);
};
