import { createSlice } from '@reduxjs/toolkit';
import * as reducers from 'Redux/Reducers/InsuranceCooperation';
import { insuranceCooperationExtraReducers as extraReducers } from 'Redux/Reducers/InsuranceCooperation/extraReducers';

export interface IInsuranceCooperationForm {
    user: {
        name?: string,
        email: string,
        insuranceNumber?: string,
        [key: string]: unknown
    },
    metaData?: Record<string, unknown>
}

export interface IInsuranceCooperationState {
    provider: string|null,
    form: IInsuranceCooperationForm|null,
    showFormScreen: string,
    wrongOtp: boolean,
    updatedAt: number,
    openRequests: string[],
    failedRequests: string[],
}

export const insuranceCooperationSlice = createSlice({
    name: 'insuranceCooperation',
    initialState: {
        provider: null,
        form: null,
        showFormScreen: 'FORM',
        wrongOtp: false,
        updatedAt: 0,
        openRequests: [],
        failedRequests: [],
    },
    reducers,
    extraReducers,
});
