import { FormikErrors, FormikHandlers } from 'formik';
import React, { ChangeEvent, FC, ReactNode } from 'react';
import Style from './FormField.style';
import { Input, Paragraph, SelectBox, Toggle } from 'View/Common';

export type TFormFieldProps = {
  id: string;
  name: string;
  value: string;
  label?: ReactNode;
  type?: string;
  className?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
  onChange?: FormikHandlers['handleChange'];
  onBlur?: FormikHandlers['handleBlur'];
  placeholder?: string;
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
};

export const FormField: FC<TFormFieldProps> = ({
  id,
  type = 'input',
  onChange,
  onBlur,
  value,
  className = '',
  name,
  placeholder,
  label,
  errorMessage,
  options,
}) => {
  const handleOnChange = (evt: ChangeEvent<any>) => {
    onChange && onChange(evt);
  };

  const handleToggleChange = (value: any) => {
    // Create an event-like object or adapt the value as needed
    const event = {
      target: {
        name,
        value: value?.target?.checked,
      },
    };
    handleOnChange(event as ChangeEvent<any>); // Cast to match expected type
  };

  const getErrorMessage = (error: any): string | undefined => {
    if (typeof error === 'string') {
      return error;
    } else if (Array.isArray(error)) {
      // Assuming the first element is a string or has a toString method
      return error[0]?.toString();
    } else if (typeof error === 'object') {
      return error.message || Object.values(error).join(', ');
    }
    return undefined;
  };

  const renderField = () => {
    const defaultProps = {
      id,
      name,
      placeholder,
      className: `form-field-input form-field-input-${name} ${errorMessage ? 'form-field-input-error' : ''} ${className}`,
      onChange: handleOnChange,
      onBlur,
    };

    switch (type.toLowerCase()) {
      case 'checkbox':
        return <input {...defaultProps} type="checkbox" />;
      case 'select':
        return (
          <SelectBox
            {...defaultProps}
            options={options}
            value="Geschlecht"
            valid={errorMessage === undefined}
            onChange={handleOnChange}
            feedbackMessage={[getErrorMessage(errorMessage)]}
            onBlur={onBlur}
          />
        );
      case 'toggle':
        return (
          <Toggle onChange={handleToggleChange} onBlur={onBlur} feedbackMessage={getErrorMessage(errorMessage)} name={name}>
            <Paragraph>{label}</Paragraph>
          </Toggle>
        );
      case 'text':
      case 'textarea':
        return <textarea {...defaultProps} />;
      case 'input':
      default:
        return <Input {...defaultProps} valid={errorMessage === undefined} feedbackMessage={getErrorMessage(errorMessage)} />;
    }
  };

  return <Style className={`form-field ${errorMessage ? 'form-field-error' : ''}`}>{renderField()}</Style>;
};
