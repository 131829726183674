import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { Footer_Branding } from 'View/Component';

import Header from 'components/Header';
import Section_1 from './Section_1';
import Section_2 from './Section_2';
import Section_3 from './Section_3';
import Section_4 from './Section_4';
import { Section_Cooperations } from './Section_Cooperations';
import { HealthCooperations } from 'View/Component';
import Style from './Home.style';

const Home: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;
  return (
    <>
      <Header path={path} />
      <Style>
        <Section_1 />
        <Section_2 />
        <Section_Cooperations />
        <HealthCooperations id={'slider'} />
        <Section_3 />
        <Section_4 />
        <Footer_Branding />
      </Style>
    </>
  );
};

export default Home;
