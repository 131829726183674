import styled from 'styled-components';
import { Switch } from 'antd';
import { asRem } from 'utils/asRem';
import { colors } from 'utils/colors';

export default styled(Switch) `
  background-color: ${colors.deprecated.grey.c600};
  height: ${asRem(32)};
  width: ${asRem(52)};
  
  &:hover {
    background-color: ${colors.deprecated.grey.c600};
  }
  
  &.ant-switch-checked {
    background-color: ${colors.blue.c030};

    .ant-switch-handle {
      inset-inline-start: calc(100% - ${asRem(30)});
    }
  }
  
  .ant-switch-handle {
    width: ${asRem(28)};
    height: ${asRem(28)};
    
    &:before {
      border-radius: 100%;
    }

  }
`;
