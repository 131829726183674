import { TEpic } from 'Redux/Config';
import Actions from 'Redux/Actions';
import { EMPTY, mergeMap } from 'rxjs';
import { ofType } from 'redux-observable';
import { RequestApi } from 'utils/RequestApi';

export const deleteBearerTokenEpic: TEpic = (action$) => action$.pipe(
  ofType(
    Actions.attendInsuranceCooperationUserDataFailure.type,
    Actions.clearHealthInsuranceUserData.type,
  ),
  mergeMap(() => {
    RequestApi.resetToken();
    return EMPTY;
  })
);
