import styled from 'styled-components';
import { Button } from 'antd';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';

export default styled(Button)`
  font-family: "Source Sans Pro";
  height: ${asRem(54)};
  max-width: 100%;
  color: ${colors.deprecated.white.c500};
  text-transform: uppercase;
  font-size: ${asRem(16)};
  font-weight: 600;
  letter-spacing: ${asRem(0.9)};
  border-radius: ${asRem(50)};
  white-space: nowrap;
  text-align: center;
  transition: box-shadow 0.1s ease-in-out;
  border: none;
  line-height: ${asRem(18.75)};
  padding: ${asRem(15)} ${asRem(24)};
  width: 100%;
  margin-bottom: ${asRem(16)};
  
  &:focus {
    border: none;
    color: ${colors.deprecated.white.c500};
  }
  
  &.ant-btn-default {
    background-image: linear-gradient(to right, ${colors.red.c050}, ${colors.red.c080});
    background-color: ${colors.red.c080};
    min-width: ${asRem(300)};
    align-self: center;

    &:hover {
      box-shadow: inset 0 0 0 ${asRem(1)} ${colors.beige.c020};
      background-image: linear-gradient(to left, ${colors.red.c050}, ${colors.red.c080});
      color: ${colors.deprecated.white.c500} !important;
    }
    
    &[disabled] {
      box-shadow: none;
      background-color: ${colors.grey.c200}10;
      background-image: linear-gradient(to bottom right, rgba(128, 61, 37, 0.2) -0.45%, rgba(243, 240, 229, 0.0) 99.55%);
      color: ${colors.grey.c200}90 !important;
      &:hover {
        background-color: ${colors.grey.c200}10;
      background-image: linear-gradient(to bottom right, rgba(128, 61, 37, 0.2) -0.45%, rgba(243, 240, 229, 0.0) 99.55%);
      color: ${colors.grey.c200}90 !important;
      }
    }
  }
  
  &.ant-btn-primary {
    box-shadow: 0 ${asRem(1)} ${asRem(4)} 0 rgba(18, 24, 31, 0.25);
    background-image: linear-gradient(to right, ${colors.blue.c060} 30%, ${colors.blue.c020} 160%);
    background-color: ${colors.blue.c020};
    min-width: ${asRem(300)};
    align-self: center;

    &:hover {
      box-shadow: inset 0 0 0 ${asRem(1)} ${colors.beige.c020};
      background-image: linear-gradient(to right, ${colors.blue.c020} -30%, ${colors.blue.c060} 60%);
      color: ${colors.deprecated.white.c500} !important;
    }
  }
  
  &.ant-btn-link {
    width: auto;
    padding: 0;
    text-transform: initial;
    font-weight: 200;
    margin: 0;
    letter-spacing: 0;
    height: auto;

    span:after {
      content: '';
      position: absolute;
      bottom: -${asRem(1)};
      left: 0;
      width: 100%;
      height: ${asRem(1)};
      background-color: ${colors.deprecated.white.c500};
    }
  }


  ${mediaQueries.md} {
    max-width: ${asRem(296)};
    padding-left: ${asRem(40)};
    padding-right: ${asRem(40)};
  }

`;
