import { Button, Caption, Headline, Image, Paragraph } from 'View/Common';
import { DarkContentBox, IHealthInsuranceEntry } from 'View/Component';
import { LoadingOutlined } from '@ant-design/icons';
import React, { FC, MouseEvent } from 'react';
import { useGetOffers } from 'View/HookApi/useGetOffers';
import { ROUTES } from 'utils/typings/routes';
import { navigate } from '@reach/router';
import { getRouteKeyFromUrl } from 'utils';

type TProps = {
  healthInsurance: IHealthInsuranceEntry | null;
  setShowAccessibilityModal: (show: boolean) => void;
};

export const HealthInsuranceFormCooperation: FC<TProps> = ({ healthInsurance, setShowAccessibilityModal }) => {
  const { loading, offers } = useGetOffers();

  const handleOnGoToLandingPage = (evt: MouseEvent) => {
    if (!healthInsurance?.cooperationUrl) {
      return;
    }
    const routeKey = getRouteKeyFromUrl(healthInsurance.cooperationUrl);
    if (!routeKey) {
      return window.open(healthInsurance.cooperationUrl, '_blank');
    }
    navigate(`${ROUTES[routeKey]}#form-section`).catch(console.error);
  };

  return (
    <DarkContentBox className="content-form-box center">
      <Headline level={4}>Präventionskurs</Headline>
      <Caption>inkl. 6 Monate 7Schläfer</Caption>
      <Paragraph className="price-field">0,00 €</Paragraph>
      <Headline level={4} className="crossed-out-price">
        {offers.zpp?.price?.localizedPrice || <LoadingOutlined />}
      </Headline>
      <Paragraph className="price-field"></Paragraph>
      <br />
      <Button title="Präventionskurs kaufen" onClick={handleOnGoToLandingPage} className="buy-button" disabled={loading}>
        {loading && <LoadingOutlined />}
        Jetzt kostenlos starten
      </Button>
      <br />
      <Caption small className="info-text">
        * Freischaltung endet automatisch nach einem Jahr und wird nicht verlängert. Erfordert ein Smartphone mit Android 8.0 bzw. iOS 13.0
        oder höher. Alle gesetzlichen Regelungen zum Einsatz des digitalen Präventions- bzw. Gesundheitsförderungsangebots sind
        berücksichtigt. Zu den{' '}
        <Button title="öffnet ein Fenster mit den Angaben zur Barrierefreiheit" type="link" onClick={() => setShowAccessibilityModal(true)}>
          Angaben zur Barrierefreiheit
        </Button>
      </Caption>
    </DarkContentBox>
  );
};
