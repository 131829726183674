import React, { FC } from 'react';
import RNCodeInput from 'react-code-input';
import { asRem } from 'utils';
import { colors } from '../../../utils/colors';

type TProps = {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
};


export const CodeInput: FC<TProps> = ({ value, onChange, error }) => {
  return (
    <RNCodeInput
      inputMode="numeric"
      name="otp-token"
      className="code-field"
      type="number"
      fields={6}
      value={value}
      onChange={onChange}
      inputStyle={{
        margin: asRem(4),
        width: asRem(40),
        borderRadius: asRem(6),
        fontSize: asRem(20),
        height: asRem(53),
        color: colors.grey.c500,
        textAlign: 'center',
        border: error ? `1px solid ${colors.error.c100}` : `1px solid ${colors.grey.c200}`,
        backgroundColor: colors.grey.c200
      }}
    />
  );
};
