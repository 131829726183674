import { ROUTES } from 'utils/typings/routes';

export const getRouteKeyFromUrl = (url: string): keyof typeof ROUTES | false => {
  const trimUrlScheme = url.split('//');
  if (trimUrlScheme.length > 1) {
    trimUrlScheme.shift();
  }
  const urlParts = trimUrlScheme.join('//').split('/');
  urlParts.shift();

  const _url = urlParts.join('/');
  // @ts-ignore dont check type of urlSegment against ROUTES enum
  const _urlIndex = Object.values(ROUTES).indexOf(`/${_url}`);
  if (_urlIndex < 0) {
    return false;
  }
  return Object.keys(ROUTES)[_urlIndex] as keyof typeof ROUTES;
};
