import { TEpic } from 'Redux/Config';
import Actions from 'Redux/Actions';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { RequestApi } from 'utils/RequestApi';
import {
  AttendInsuranceCooperationDocument,
  AttendInsuranceCooperationMutation,
  SignInWebUserDocument,
  SignInWebUserMutation
} from 'api/graphql';
import { getInsuranceCooperationProvider, getInsuranceFormData, getUserMetaData } from 'Redux/Queries';

export const attendInsuranceCooperationUserDataEpic: TEpic = (action$, store$) => action$.pipe(
  ofType(Actions.attendInsuranceCooperationUserDataRequest.type),
  withLatestFrom(store$),
  mergeMap(([action, store]) => {
    const formData = getInsuranceFormData(store);
    if(!formData) {
      return of(Actions.attendInsuranceCooperationUserDataFailure({ error: new Error('No user data provided') }));
    }
    const { email, insuranceNumber } = formData;
    return from(RequestApi.mutate<SignInWebUserMutation>(SignInWebUserDocument, { token: action.payload.token, email })).pipe(
      mergeMap(({ data: response }) => {
        let token: string|null = null;
        if(response?.signInWebUser?.token) {
          token = response.signInWebUser.token;
          RequestApi.setToken(token);
        }
        if(response?.signInWebUser?.user) {
          const voucherId = getInsuranceCooperationProvider(store);
          const userMetaData = getUserMetaData(store);
          return from(RequestApi.mutate<AttendInsuranceCooperationMutation>(AttendInsuranceCooperationDocument, {
            insuranceNumber,
            voucherId,
            ...userMetaData,
          })).pipe(
            mergeMap(({ data: response }) => {
              return  from([
                Actions.attendInsuranceCooperationUserDataSuccess(),
                Actions.setBearerToken({ token }),
              ]);
            }),
            catchError((error) => of(Actions.attendInsuranceCooperationUserDataFailure({ error })))
          );
        }

        return of(Actions.attendInsuranceCooperationUserDataFailure({ error: new Error('User SignIn with OTP Failed') }));
      }),
      catchError((error) => of(Actions.attendInsuranceCooperationUserDataFailure({ error })))
    );
  })

);
