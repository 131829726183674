import { Headline, Image } from '@missionme/mime_elements';
import { Slider } from 'View/Component';
import { FC, useMemo } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { CooperationStyle } from './HealthCooperations.style';
import { ISliderSettings, useSliderSettings } from 'View/HookApi/useSliderSettings';
import { useGetInsuranceCompaniesQuery } from 'api/graphql';
import { LoadingSpinner } from 'View/Common';
import { businessCooperations } from 'utils/Constants/businessCooperations';

type THealthCooperationsProps = {
  id: string;
};

export const MixedCooperations: FC<THealthCooperationsProps> = ({ id }) => {
  const { loading, data } = useGetInsuranceCompaniesQuery({
    variables: {
      isCooperationPartner: true,
    },
  });

  const activeCooperationPartners = Object.values(data?.insuranceCompanies || {});
  const combinedCoopPartners = useMemo(() => [...activeCooperationPartners, ...businessCooperations], [activeCooperationPartners]);
  const settings: ISliderSettings = useSliderSettings(combinedCoopPartners.length);

  return (
    <CooperationStyle id={id} className="text-center" verticalPadding={{ sm: 0, md: 30, lg: 40 }} type="full-width">
      <Headline level={3}>Diese Unternehmenspartner vertrauen uns bereits</Headline>
      <Slider key={settings.initialSlide} settings={settings}>
        {combinedCoopPartners.map((cooperation) =>
          cooperation.cooperationLogo ? <Image key={cooperation.name} src={cooperation.cooperationLogo} className="logo" /> : null,
        )}
      </Slider>
    </CooperationStyle>
  );
};
