export default function localizeAmount(
  amount: number | null,
  cutDigits: boolean | true
): string {
  if (!amount) {
    return '';
  }

  if (cutDigits) {
    return amount
      .toLocaleString('de-DE', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
      })
      .substr(0, 4);
  } else {
    return amount.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
}
