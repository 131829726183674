import React, { FunctionComponent } from 'react';
import Style from './BgColorContainer.style';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export const BgColorContainer: FunctionComponent<IProps> = ({ children, className, style }) => {
  return (
    <Style className={className} style={style}>
      {children}
    </Style>
  );
};
