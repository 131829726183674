import { combineEpics } from 'redux-observable';
import { applicationBootstrappedEpic } from './ApplicationBootstrapped/ApplicationBootstrappedEpic';
import { showErrorNotificationEpic } from './ShowErrorNotification/ShowErrorNotificationEpic';
import { deleteBearerTokenEpic } from './DeleteBearerToken/DeleteBearerTokenEpic';

export const applicationEpics = combineEpics(
  applicationBootstrappedEpic,
  deleteBearerTokenEpic,
  showErrorNotificationEpic,
);
