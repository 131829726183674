import { useEffect } from 'react';
import { globalHistory } from '@reach/router';

export const ScrollToTop = () => {
  const handleScroll = () => {
    setTimeout(() => {
      const id = window.location?.hash?.replace('#', '');
      const offset = 80; 

      if (id) {
        const element = document.getElementById(id);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  useEffect(() => {
    const unlisten = globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        handleScroll();
      }
    });

    handleScroll();

    return () => {
      unlisten();
    };
  }, []);

  return null;
};
