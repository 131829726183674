import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';
import { asRem } from 'utils/asRem';

export default styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: ${asRem(40)};
  background-color: ${colors.blue.c080}80;

  ${mediaQueries.md} {
    padding-bottom: ${asRem(70)};
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;
