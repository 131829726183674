import React, { FunctionComponent } from 'react';
import { Modal } from 'View/Component';
import { Bold, Headline, Paragraph } from 'View/Common';

interface IProps {
  open?: boolean;
  onClose?: () => void;
}

export const AccessibilityModal: FunctionComponent<IProps> = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} title={<Headline level={2}>Angaben zur Barrierefreiheit</Headline>}>
      <Paragraph>
        Diese Erklärung zur Barrierefreiheit gilt für die unter www.7schlaefer.app verfügbare App 7Schläfer.
        Getestet wurde die Software auf den Plattformen Android und iOS. Als Verantwortlicher für die App sind wir bemüht,
        unsere Software im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes des Bundes (BGG) sowie der
        Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur Umsetzung der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.
      </Paragraph>
      <Headline level={3}>
        Wann wurde die Erklärung zur Barrierefreiheit erstellt bzw. aktualisiert?
      </Headline>
      <Paragraph>
        Diese Erklärung zur Barrierefreiheit (Stand: 01.12.2023) gilt für die verfügbare Software 7Schläfer und beruht
        auf einer im Zeitraum vom 1.11.2023 bis 10.11.2023 durchgeführten Selbstbewertung auf Basis des Entwurfs vom Stand
        09.03.2021 des BIK BITV-Tests für mobile Apps (https://github.com/BIK-BITV/BIK-App-Test).
      </Paragraph>
      <Headline level={3}>Welche Vorkehrungen sind getroffen, um die App barrierefrei zu gestalten?</Headline><br />
      <Paragraph>
        <Bold>Aufbau</Bold><br />
        <ul>
          <li>Verwendung von Stylesheets für das Design (Bedienhilfe Sehen)</li>
          <li>Bereitstellung aller Lern-Inhalte als Audio Files (Bedienhilfe Hören)</li>
          <li>eindeutig identifizierbare Hyperlinks, in begleitenden Email-Aussendungen (Bedienhilfe Sehen)</li>
          <li>Linkziele mit informativem „Title”-Attribut (Beispielsweise: Link öffnet PDF-Datei in neuem Fenster) (Bedienhilfe Sehen)</li>
          <li>Links auf Nicht-HTML-Seiten mit Beschreibung zum verlinkten Datenformat (z.B. PDF, Word usw.) (Bedienhilfe Sehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Farben und Kontraste
      </Headline>
      <Paragraph>
        <ul>
          <li>farbige und grafische Informationen sind in der Regel auch ohne Farbe sinnvoll nutzbar (Bedienhilfe Sehen)</li>
          <li>Nach Möglichkeit Vermeidung von problematischen Farbkombinationen (gleicher Grauwert in S/W-Umsetzung) (Bedienhilfe Sehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Fließtext und Überschriften
      </Headline>
      <Paragraph>
        <ul>
          <li>Einsatz variabler Schriftgrößen (Bedienhilfe Sehen)</li>
          <li>Verwendung von Unterstreichungen nur bei Verlinkungen (Bedienhilfe Sehen)</li>
          <li>Hintergrund und Schrift verfügen in der Regel über ausreichend Kontrast (Bedienhilfe Sehen)</li>
          <li>Sicherung der Erkennbarkeit und Lesbarkeit von Textinhalten bei 200%-Vergrößerung (Bedienhilfe Sehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Textuelle Inhalte
      </Headline>
      <Paragraph>
        <ul>
          <li>Verwendung einer einfachen und klaren Sprache (Bedienhilfe Verstehen)</li>
          <li>Vermeidung von Umgangssprache und Slangwörtern (Bedienhilfe Verstehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Grafische Inhalte
      </Headline>
      <Paragraph>
        <ul>
          <li>Vermeidung von animierten Grafiken, wo möglich (Bedienhilfe Sehen)</li>
          <li>Verzicht auf flackernde, blinkende oder sich bewegende Elemente (Bedienhilfe Sehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Multimediale Inhalte
      </Headline>
      <Paragraph>
        <ul>
          <li>Bereitstellung von Untertiteln bei Videos sowie Textfassungen bei Tondokumenten und Versendung verschriftlichter Texte auf Wunsch (E-Mail) (Bedienhilfe Hören/Sehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Officedokumente und PDFs
      </Headline>
      <Paragraph>
        <ul>
          <li>hinterlegen des Dokumententitels in den Meta-Daten des Dokuments (Bedienhilfe Sehen)</li>
          <li>Wahrung einer logischen Lesereihenfolge (Bedienhilfe Verstehen)</li>
        </ul>
      </Paragraph>
      <Headline level={3}>
        Wie barrierefrei ist die Anwendung insgesamt?
      </Headline>
      <Paragraph>
        Die Anwendung ist in Teilen barrierefrei. In Teilen sind noch nicht alle Anforderungen der Barrierefreie-Informationstechnik-Verordnung (BITV) 2.0 erfüllt,
        werden aber voraussichtlich 2024 nachgebessert.
      </Paragraph>
      <Headline level={3}>
        Barrieren melden: Kontakt zu den Ansprechpartner:innen
      </Headline>
      <Paragraph>
        Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit erfragen?
        Bitte kontaktieren Sie uns per E-Mail unter <a href="mailto:info@7schlaefer.app" title="E-Mail senden an: info@7schlaefer.app">info@7schlaefer.app</a>. Ihre Anfragen werden durch unseren Support umgehend bearbeitet und beantwortet.
      </Paragraph>
      <Headline level={3}>
        Kontakt zur Schlichtungsstelle
      </Headline>
      <Paragraph>
        Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde,
        können Sie sich an die Schlichtungsstelle nach § 16 BGG wenden. Die Schlichtungsstelle BGG hat die Aufgabe,
        bei Konflikten zum Thema Barrierefreiheit zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes eine außergerichtliche
        Streitbeilegung zu unterstützen. Das Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden.
        Weitere Informationen zum Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter:
        <a href={'www.schlichtungsstelle-bgg.de'} title="Link zur www.schlichtungsstelle-bgg.de" target="_blank">www.schlichtungsstelle-bgg.de</a>.
        Direkt kontaktieren können Sie die Schlichtungsstelle BGG unter <a href="mailto:info@schlichtungsstelle-bgg.de" title="E-Mail senden an: info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a>
      </Paragraph>
    </Modal>
  );
};
