import Image1x from './pfeile_kk.png';
import Image2x from './pfeile_kk@2x.png';
import Image3x from './pfeile_kk@3x.png';

export default {
  src: Image1x,
  srcSet: {
    '2x': Image2x,
    '3x': Image3x,
  },
};
