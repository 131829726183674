export enum ACTION_TYPES {
  UPDATE_ENTERED_COUPON_CODE = "UPDATE_ENTERED_COUPON_CODE",
  UPDATE_OFFERS = "UPDATE_OFFERS",
  UPDATE_PURCHASE = "UPDATE_PURCHASE",
  UPDATE_REDEEM_VOUCHER = "UPDATE_REDEEM_VOUCHER",
  UPDATE_STRIPE_SETUP_INTENT = "UPDATE_STRIPE_SETUP_INTENT",
  UPDATE_BILLING_DETAILS = "UPDATE_BILLING_DETAILS",
  UPDATE_IS_BILLING_DETAILS_DIRTY = "UPDATE_IS_BILLING_DETAILS_DIRTY",
  SET_SELECTED_OFFER = "SET_SELECTED_OFFER",
  CLEAN_UP_SELECTED_OFFER = "CLEAN_UP_SELECTED_OFFER",
  CLEAN_UP_ENTEREDCODE = "CLEAN_UP_ENTEREDCODE",
  CLEAN_UP_REDEEM_VOUCHER = "CLEAN_UP_REDEEM_VOUCHER",
  UPDATE_CANCELLATION = "UPDATE_CANCELLATION",
}
