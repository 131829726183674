import React, { FunctionComponent } from 'react';
import Style from './Sup.style';


interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Sup: FunctionComponent<IProps> = ({ children, ...props }) => (
  <Style {...props}>{children}</Style>
);
