import Stripe from '@stripe/stripe-js';
import React, { useReducer } from 'react';
import {
  Offer,
  PurchaseStripeOfferMutationPayload,
  SinglePaymentOffer,
  SubscriptionOffer,
  RedeemVoucherMutationPayload,
  CancellationRequest
} from 'api/graphql';
import { ACTION_TYPES } from 'utils/typings/actionTypes';

export type BillingDetails = {
  addressLine1: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  zipCode: string;
};

interface IContextValue {
  enteredCouponCode: string;
  productId?: string;
  purchase?: PurchaseStripeOfferMutationPayload;
  stripeSetupIntent?: Stripe.SetupIntent | undefined;
  billingDetails?: BillingDetails;
  terminateDetails?: CancellationRequest;
  isBillingDetailsDirty?: boolean;
  selectedOffer?: SinglePaymentOffer | SubscriptionOffer;
  offers?: Offer[];
  redeemVoucher?: RedeemVoucherMutationPayload;
}

export function isSinglePaymentOffer(
  offer: SinglePaymentOffer | SubscriptionOffer
): offer is SinglePaymentOffer {
  return offer.__typename === 'SinglePaymentOffer';
}

export function isSubscriptionOffer(
  offer: SinglePaymentOffer | SubscriptionOffer
): offer is SubscriptionOffer {
  return offer.__typename === 'SubscriptionOffer';
}

export const contextValue: IContextValue = { enteredCouponCode: '' };

type StoreActions =
  | { type: ACTION_TYPES.UPDATE_ENTERED_COUPON_CODE; enteredCouponCode: string }
  | { type: ACTION_TYPES.UPDATE_OFFERS; offers: Offer[] }
  | {
      type: ACTION_TYPES.UPDATE_OFFERS;
      offers: Offer[];
    }
  | {
      type: ACTION_TYPES.UPDATE_PURCHASE;
      purchase: PurchaseStripeOfferMutationPayload;
    }
  | {
      type: ACTION_TYPES.UPDATE_STRIPE_SETUP_INTENT;
      stripeSetupIntent: Stripe.SetupIntent | undefined;
    }
  | {
      type: ACTION_TYPES.UPDATE_BILLING_DETAILS;
      billingDetails: BillingDetails;
    }
  | {
      type: ACTION_TYPES.UPDATE_IS_BILLING_DETAILS_DIRTY;
      isBillingDetailsDirty: boolean;
    }
  | {
      type: ACTION_TYPES.SET_SELECTED_OFFER;
      selectedOffer: SinglePaymentOffer | SubscriptionOffer;
    }
  | {
      type: ACTION_TYPES.CLEAN_UP_SELECTED_OFFER;
    }
  | {
      type: ACTION_TYPES.CLEAN_UP_ENTEREDCODE;
    }
  | {
      type: ACTION_TYPES.UPDATE_REDEEM_VOUCHER;
      redeemVoucher: RedeemVoucherMutationPayload;
    }
  | {
      type: ACTION_TYPES.CLEAN_UP_REDEEM_VOUCHER;
    }
  | {
      type: ACTION_TYPES.UPDATE_CANCELLATION;
      terminateDetails: CancellationRequest;
    };

const initialState = { ...contextValue };

const storeReducer = (store: IContextValue, action: StoreActions) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_BILLING_DETAILS:
      return { ...store, billingDetails: action.billingDetails };
    case ACTION_TYPES.UPDATE_IS_BILLING_DETAILS_DIRTY:
      return { ...store, isBillingDetailsDirty: action.isBillingDetailsDirty };
    case ACTION_TYPES.UPDATE_OFFERS:
      return { ...store, offers: action.offers };
    case ACTION_TYPES.UPDATE_STRIPE_SETUP_INTENT:
      return { ...store, stripeSetupIntent: action.stripeSetupIntent };
    case ACTION_TYPES.UPDATE_ENTERED_COUPON_CODE:
      return { ...store, enteredCouponCode: action.enteredCouponCode };
    case ACTION_TYPES.UPDATE_PURCHASE:
      return { ...store, purchase: action.purchase };
    case ACTION_TYPES.SET_SELECTED_OFFER:
      return { ...store, selectedOffer: action.selectedOffer };
    case ACTION_TYPES.CLEAN_UP_SELECTED_OFFER:
      return { ...store, selectedOffer: undefined };
    case ACTION_TYPES.CLEAN_UP_ENTEREDCODE:
      return { ...store, enteredCouponCode: '' };
    case ACTION_TYPES.UPDATE_REDEEM_VOUCHER:
      return { ...store, redeemVoucher: action.redeemVoucher };
    case ACTION_TYPES.CLEAN_UP_REDEEM_VOUCHER:
      return { ...store, redeemVoucher: undefined };
    case ACTION_TYPES.UPDATE_CANCELLATION:
      return { ...store, terminateDetails: action.terminateDetails };
    default:
      return store;
  }
};

export function useStore(): [IContextValue, React.Dispatch<StoreActions>] {
  const [state, dispatch] = useReducer(storeReducer, initialState);
  return [state, dispatch];
}

export type IContextType = [IContextValue, React.Dispatch<StoreActions>];

export const Store = React.createContext<IContextType>([
  contextValue,
  () => null
]);
