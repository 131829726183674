import { Button } from 'View/Common';
import React, { useState, MouseEvent } from 'react';
import { HealthRequirementsModal } from 'View/Component/Modals';

export const HealthPreConditionsLabel = () => {

  const [showHealthRequirements, setShowHealthRequirements] = useState(false);

  const handleOnClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    setShowHealthRequirements(true);
  };

  return (
    <>
      <HealthRequirementsModal open={showHealthRequirements} onClose={() => setShowHealthRequirements(false)} />

      Ich akzeptiere die{' '}
      <Button
        title="öffnet ein Fenster mit dem gesundheitlichen Voraussetzungen"
        type="link"
        className="form-regulatory-link"
        onClick={handleOnClick}
      >
        Gesundheitsvoraussetzungen
      </Button>{' '}
      und bestätige hiermit, dass keine Kontraindikationen für die Absolvierung des Kurses vorliegen.
    </>
  );
};
