import { IInsuranceCooperationState } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';
import { Draft } from '@reduxjs/toolkit';

export const clearHealthInsuranceUserData = (state: Draft<IInsuranceCooperationState>) => {
  state.form = null;
  state.provider = null;
  state.wrongOtp = false;
  state.showFormScreen = 'FORM';
  state.openRequests = [];
  state.failedRequests = [];
  state.updatedAt = Date.now();
};
