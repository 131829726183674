import { IInsuranceCooperationState } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';
import { Draft, PayloadAction } from '@reduxjs/toolkit';

export type TRegisterWithInsuranceNumberRequestPayload = { user: { name?: string, email: string, insuranceNumber?: string }, userMetaData: Record<string, unknown>, withoutInsuranceNumber?: boolean}

export const registerWithInsuranceNumberRequest = (state: Draft<IInsuranceCooperationState>, { type, payload }: PayloadAction<TRegisterWithInsuranceNumberRequestPayload>) => {
  state.openRequests = [ ...state.openRequests, type ];
  state.failedRequests = state.failedRequests.filter((actionType) => actionType !== 'insuranceCooperation/registerWithInsuranceNumberFailure');
  state.form = payload;
  state.updatedAt = Date.now();
};

export const registerWithInsuranceNumberSuccess = (state: Draft<IInsuranceCooperationState>) => {
  state.updatedAt = Date.now();
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'insuranceCooperation/registerWithInsuranceNumberRequest');
};

export const registerWithInsuranceNumberFailure = (state: Draft<IInsuranceCooperationState>, { type, payload }: PayloadAction<{ error: any }>) => {
  state.updatedAt = Date.now();
  state.failedRequests = [ ...state.failedRequests, type ];
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'insuranceCooperation/registerWithInsuranceNumberRequest');
};
