import React from 'react';
import { StyledHomeSection } from './HomeSection.style';
const Section_3 = () => {
  return (
    <StyledHomeSection>
      <div className="wrapper">
        <section className="home__section-3">
          <div className="wrapper__flex-col-row">
            <h2 className="heading heading-2 display--mobile">Stimme und Kopf hinter 7Schläfer</h2>
            <div className="home__image-portrait-albrecht display--mobile">
              <span className="visuallyhidden">Bild: Schlafexperte Dr. Albrecht Vorster</span>
            </div>
            <div className="home__image-portrait-albrecht display--desktop">
              <span className="visuallyhidden">Bild: Schlafexperte Dr. Albrecht Vorster</span>
            </div>
            <div className="wrapper__flex-1">
              <h2 className="heading heading-2 ta-left display--desktop">Stimme und Kopf hinter 7Schläfer</h2>
              <h4 className="heading heading-4">Albrecht Vorster</h4>
              <p className="paragraph">
                Albrecht Vorster ist Schlafforscher, Biologe und Ideengeber für 7Schläfer. Mit seiner Stimme begleitet er dich persönlich
                durch deine sieben Kurswochen.
              </p>
              <h4 className="heading heading-4">Forschung</h4>
              <p className="paragraph">
                Seine schnarchende Großmutter war es, die Albrecht das erste Mal über Schlafprobleme nachdenken ließ. Seither lässt ihn
                dieses Feld der Wissenschaft nicht mehr los. Nach Zwischenstationen in einigen der besten Schlaflaboren der Welt, forscht er
                heute in Bern am Schlaf von Krankenhauspatienten.
              </p>
              <p className="paragraph">
                Albrecht schläft am liebsten von Mitternacht bis acht Uhr morgens. Außerdem liebt er seinen mittäglichen Powernap.
              </p>

              <div className="blockquote__wrapper">
                <div className="blockquote__icon-speechbubble">
                  <span className="visuallyhidden">Icon: Sprechblase</span>
                </div>
                <div className="blockquote__text-holder">
                  <p className="paragraph blockquote">
                    "7Schläfer ist für mich ein Weg, Millionen von Menschen ganz konkret zu helfen. Ein Großteil der Schlafprobleme lässt
                    sich durch unser Kursprogramm lösen. Guter Schlaf bringt Energie, Lebensfreude und Gesundheit."
                  </p>
                  <p className="paragraph blockquote__subline">
                    Albrecht Vorster, Biologe, Schlafforscher und Autor des Buches „Warum wir schlafen“
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </StyledHomeSection>
  );
};

export default Section_3;
