import React, { FunctionComponent } from 'react';
import Style from './Bold.style';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Bold: FunctionComponent<IProps> = ({ children, ...props }) => (
  <Style {...props}>{children}</Style>
);
