import { useNavigate } from '@reach/router';
import React, { useContext } from 'react';
import { SubscriptionOffer } from 'api/graphql';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';
import Style from './ZppOfferBox.style';
import { Button, Caption, Headline, Image } from 'View/Common';
import ZppSleepingLuna from 'img/ZppSleepingLuna';

interface Props {
  offer: SubscriptionOffer;
}

export const ZppOfferBox: React.FC<Props> = ({ offer }) => {
  const [, dispatch] = useContext(Store);
  const navigate = useNavigate();
  const selectOffer = (offer: SubscriptionOffer) => {
    navigate(ROUTES.ZPP_LANDING_PAGE).catch(console.error);
  };

  return (
    <Style  data-cy-name="offer-prevention-box">
      <div className="content-wrapper text-center">
        <div className="box-badge">Die Krankenkasse zahlt!</div>
        <Headline level={3} className="offer-label">Präventionskurs</Headline>
        { offer?.crossedOutPrice && (<Headline level={3} className="crossed-out-price">{ offer.crossedOutPrice.localizedPrice.trim() }</Headline>)}
        <Headline level={2} className="price-label">{ offer.price.localizedPrice.trim() }</Headline>
        <Caption small className="price-info-box">
          inkl. 12 Monate 7Schläfer
        </Caption>
        <br />
        <Button
          onClick={(evt) => selectOffer(offer)}
          className={`${offer.name.toLowerCase()}`}
          data-testid={`e2e_btn_buy_${offer.interval}`}
          data-cy-name="buy-prevention-button"
          title="Dieses Angebot jetzt kaufen"
        >
          jetzt kaufen
        </Button>
      </div>
      <Image
        className="sleeping-luna-image"
        image={ZppSleepingLuna.src}
        srcSet={ZppSleepingLuna.srcSet}
        alt="Luna schlafend mit Erstattungscheck in der Hand"
      />

    </Style>
  );
};
