export const environment = (() => {
  switch (window.location.hostname) {
    case '7schlaefer.app':
      return 'production';
    case 'krankenkasse.7schlaefer.app':
    case 'staging.7schlaefer.app':
      return 'staging';
    case 'localhost':
      return 'development';
    default:
      return 'test';
  }
})();
