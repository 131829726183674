import { TEpic } from 'Redux/Config';
import Actions from 'Redux/Actions';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { RequestApi } from 'utils/RequestApi';
import {
  RegisterWebUserDocument,
  RegisterWebUserMutation
} from 'api/graphql';
import { getInsuranceCooperationProvider } from 'Redux/Queries';

export const registerUserWithOnetimePasswordRequestEpic: TEpic = (action$, store$) => action$.pipe(
  ofType(Actions.registerUserWithOnetimePasswordRequest.type),
  withLatestFrom(store$),
  mergeMap(([action, store]) => from(
    RequestApi.mutate<RegisterWebUserMutation>(RegisterWebUserDocument, { nickname: action.payload.name, email: action.payload.email, termsAccepted: true })).pipe(
      mergeMap(({ data: response, errors }) => {
        const provider = getInsuranceCooperationProvider(store);
        if(!provider) {
          return of(Actions.registerUserWithOnetimePasswordFailure({ error: new Error('No valid provider given') }));
        }
        if ('result' in (response?.registerWebUser || {})) {
          return of(Actions.registerUserWithOnetimePasswordSuccess());
        }

        return of(Actions.registerUserWithOnetimePasswordFailure({ error: errors || new Error('Unknown issue') }));
      }),
      catchError((error) => {
        const provider = getInsuranceCooperationProvider(store);
        if(!provider) {
          return of(Actions.registerUserWithOnetimePasswordFailure({ error: new Error('No valid provider given') }));
        }

        // ToDo: mock backend error
        if(error?.networkError?.result?.errors?.length && error?.networkError?.result?.errors[0].message === 'AUTHENTICATION_FAILED') {
          return of(Actions.registerUserWithOnetimePasswordSuccess());
        }

        return of(Actions.registerUserWithOnetimePasswordFailure({ error }));
      })
    )
  )
);

