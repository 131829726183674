import React, { FunctionComponent } from 'react';
import Style from './Caption.style';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  small?: boolean;
  dataCyName?: string;
}

export const Caption: FunctionComponent<IProps> = ({ children, dataCyName, className, small, ...props }) => (
  <Style data-cy-name={dataCyName} {...props} className={['caption', className, small && 'small'].filter((c) => !!c).join(' ')}>{children}</Style>
);
