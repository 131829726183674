import { createSlice } from '@reduxjs/toolkit';
import * as applicationReducers from 'Redux/Reducers/Application';

export interface IApplicationState {
    isBootstrapped: boolean,
    networkError: boolean,
    updatedAt: number,
}

export const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        isBootstrapped: false,
        networkError: false,
        updatedAt: 0,

    },
    reducers: applicationReducers,
});
