import React, { FunctionComponent } from 'react';
import Style, { dropdownStyle } from './Select.style';
import { SelectProps } from 'antd';
import type { BaseOptionType } from 'rc-select/lib/Select';

export const Select: FunctionComponent<SelectProps<any, BaseOptionType>> = ( props ) => {
  return (
    <Style
      allowClear
      filterOption={(input, option) => (`${option?.label ?? ''}`.toUpperCase()).includes(`${input}`.toUpperCase())}
      showSearch
      menuItemSelectedIcon={<div>t</div>}
      dropdownStyle={ dropdownStyle }
      { ...props }
    />
  );
};
