import zppIllu from './krankenkassen-illu.png';
import zppIllu2x from './krankenkassen-illu@2x.png';
import zppIllu3x from './krankenkassen-illu@3x.png';

export default {
  src: zppIllu,
  srcSet: {
    '2x': zppIllu2x,
    '3x': zppIllu3x
  }
};
