import { apolloClient } from './ApolloClient';
import { ApolloQueryResult, DocumentNode, FetchResult, TypedDocumentNode } from '@apollo/client';

export type TQuery = DocumentNode | TypedDocumentNode<any, Record<string, unknown>>
export type TArgument = Record<string, unknown>;

export interface IRequestApi {
  _bearerToken: string | null;
  setToken: (token: string) => void;
  getToken: () => string|null;
  resetToken: () => void;
  query: <TResponse>(query: TQuery, variables?: TArgument) => Promise<ApolloQueryResult<TResponse>>;
  mutate: <TResponse>(query: TQuery, variables?: TArgument) => Promise<FetchResult<TResponse>>;
}

export const RequestApi: IRequestApi = {
  _bearerToken: null,
  setToken: (token: string) => {
    RequestApi._bearerToken = token;
  },
  getToken: () => {
    return RequestApi._bearerToken;
  },
  resetToken: () => {
    RequestApi._bearerToken = null;
  },
  query: <TResponse>(query: TQuery, variables?: TArgument) => {
    return apolloClient.query<TResponse>({ query, variables });
  },
  mutate: <TResponse>(query: TQuery, variables?: TArgument) => {
    return apolloClient.mutate<TResponse>({ mutation: query, variables });
  }
};
