import styles from 'styled-components';

export default styles.div `
  
  [type="checkbox"] {
    appearance: auto;
  }
  
  .form-field {
    display: flex;
  }

`;
