import React from 'react';
import StoreButtons from 'components/StoreButtons';
import { StyledHomeSection } from './HomeSection.style';

const Section_4 = () => {
  return (
    <StyledHomeSection>
      <div className="wrapper">
        <section className="home__section-4">
          <h2 className="heading heading-2">App jetzt herunterladen!</h2>
          <StoreButtons />
          <div className="wrapper__flex-row margin-top-50">
            <div className="home__image-phone home__image-phone--left">
              <span className="visuallyhidden">Bild: iPhone 7Schläfer App Homescreen</span>
            </div>
            <div className="home__image-phone home__image-phone--right">
              <span className="visuallyhidden">Bild: iPhone 7Schläfer App Abendroutine</span>
            </div>
          </div>
        </section>
      </div>
    </StyledHomeSection>
  );
};

export default Section_4;
