import { breakpoints } from 'View/Theme';
import { useState, useEffect } from 'react';

export const MAX_SMALL_SET_SIZE = 7;
export const HALF_LOGO_BOX_WIDTH = '-100px';
export const NO_PADDING = '0px';

export interface ISliderSettings {
  dots?: boolean;
  infinite?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  autoplay?: boolean;
  speed?: number;
  autoplaySpeed?: number;
  variableWidth?: boolean;
  centerMode?: boolean;
  initialSlide?: number;
  centerPadding?: string;
  swipe?: boolean;
  accessibility?: boolean;
  arrows?: boolean;
  swipeToSlide?: boolean;
  pauseOnHover?: boolean;
  pauseOnFocus?: boolean;
  responsive?: Array<{
    breakpoint: number;
    settings: ISliderSettings;
  }>;
}

export const useSliderSettings = (totalLogoCount: number) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const isSmallSetOfIcons = totalLogoCount <= MAX_SMALL_SET_SIZE;
    const isEvenSetOfIcons = totalLogoCount % 2 === 0;

    const defaultSettings = {
      dots: false,
      infinite: !isSmallSetOfIcons,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: !isSmallSetOfIcons,
      speed: isSmallSetOfIcons ? 0 : 1000,
      autoplaySpeed: isSmallSetOfIcons ? 0 : 5000,
      variableWidth: true,
      centerMode: true,
      initialSlide: isEvenSetOfIcons ? totalLogoCount / 2 : Math.floor(totalLogoCount / 2),
      centerPadding: isEvenSetOfIcons ? HALF_LOGO_BOX_WIDTH : NO_PADDING,
      swipe: false,
      accessibility: false,
      arrows: !isSmallSetOfIcons,
    };

    const defaultTabletAndMobileSettings = {
      dots: true,
      infinite: true,
      swipe: true,
      swipeToSlide: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      arrows: false,
      centerMode: false,
      variableWidth: true,
      pauseOnHover: true,
      pauseOnFocus: true,
    };

    const newSettings = {
      ...defaultSettings,
      responsive: [
        {
          breakpoint: parseInt(breakpoints.xl, 10),
          settings: {
            ...defaultSettings,
          },
        },
        {
          breakpoint: parseInt(breakpoints.lg, 10),
          settings: {
            ...defaultSettings,
            swipe: totalLogoCount > 5,
            accessibility: totalLogoCount > 5,
            dots: true,
          },
        },
        {
          breakpoint: parseInt(breakpoints.md, 10),
          settings: {
            ...defaultSettings,
            ...defaultTabletAndMobileSettings,
          },
        },
        {
          breakpoint: parseInt(breakpoints.sm, 10),
          settings: {
            ...defaultSettings,
            ...defaultTabletAndMobileSettings,
            initialSlide: 1,
          },
        },
      ],
    };

    setSettings(newSettings);
  }, [totalLogoCount]);

  return settings;
};
