import { errorActionConstants } from 'Redux/Actions';
import { Epic, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

export const showErrorNotificationEpic: Epic = (action$) => {
  return action$.pipe(
    // @ts-ignore
    ofType(...errorActionConstants),
    mergeMap((action) => {
      // ToDo: Global Error Handler
      return EMPTY;
    })
  );
};
