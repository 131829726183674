import React, { FunctionComponent, JSX } from 'react';
import  { StyleH1, StyleH2, StyleH3, StyleH4, StyleH5 } from './Headline.style';


interface IProps {
  level?: 1 | 2 | 3 | 4 | 5;
  className?: string;
  children?: React.ReactNode;
}

export const Headline: FunctionComponent<IProps> = ({ level, className = '', children }) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  switch(level) {
    case 1: return <StyleH1 className={className}>{children}</StyleH1>;
    case 2: return <StyleH2 className={className}>{children}</StyleH2>;
    case 3: return <StyleH3 className={className}>{children}</StyleH3>;
    case 4: return <StyleH4 className={className}>{children}</StyleH4>;
    case 5: return <StyleH5 className={className}>{children}</StyleH5>;
    default: return <Tag className={className}>{children}</Tag>;
  }

};
