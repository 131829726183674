import React from 'react';
import {
  SinglePaymentOffer,
  SubscriptionOffer
} from 'api/graphql';
import { isSubscriptionOffer, Store } from 'utils/store';
import localizeAmount from 'utils/localizeAmount';
import Style from './OfferBox.style';
import { Button, Caption, Col, Headline, Row, Sup } from 'View/Common';
import { useSelectOffer } from 'View/HookApi/useSelectOffer';

interface Props {
  offer: SinglePaymentOffer | SubscriptionOffer;
}

export const OfferBox: React.FC<Props> = ({ offer }) => {

  const { selectOffer } = useSelectOffer();

  const isSubscription = isSubscriptionOffer(offer);

  return (
    <Style className={offer.highlight ? 'highlighted' : '' } data-cy-name="offer-box">
      { offer.highlight && <div className="box-badge">Am beliebtesten</div> }
      <Row gutter={16} className="body">
        <Col xs={24} sm={24} md={11} className="price-information">
          { offer.crossedOutPrice && (<Headline level={3} className="crossed-out-price">{ offer.crossedOutPrice.localizedPrice.trim() }</Headline>)}
          <Headline level={2} className="price-label">{ offer.price.localizedPrice.trim() }</Headline>
          <Caption className="price-info-box">
            <span>
              { isSubscription ? offer.interval === 'MONTHLY' ? 'monatlich' : 'im Jahr' : 'einmalig'}
              { isSubscription && ['MONTHLY', 'YEARLY'].includes(offer.interval) && (
                <Sup>*</Sup>
              )}
            </span>
            <span>
            { isSubscription && offer.interval === 'YEARLY' && (
              <>
                ({ localizeAmount(offer.price.cents / 1200, true) } €/Monat)
              </>
              )}
            </span>
          </Caption>
        </Col>
        <Col xs={24} sm={24} md={13} className="cta-wrapper text-center">
          <Headline level={2} className="offer-label">{ offer.name }</Headline>
          <Button
            type="primary"
            onClick={(evt) => selectOffer(evt, offer)}
            className={`${offer.name.toLowerCase()}`}
            data-testid={`e2e_btn_buy_${isSubscription ? offer.interval : 'ONETIME'}`}
            data-cy-name="buy-button"
            title="Dieses Angebot jetzt kaufen"
          >
            jetzt kaufen
          </Button>
        </Col>
      </Row>

    </Style>
  );
};
