import React from 'react';

import error from 'img/error.svg';
import { RouteComponentProps } from '@reach/router';

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <main className="main main--no-footer-cta error-page">
        <div className="wrapper flex-column padding-top-150 padding-bottom-100">
          <img src={error} alt="error" className="error-img" />
          <div className="box box__rounded">
            <div className="margin-bottom-33 margin-top-120 wrapper__box flex-column">
              <h2 className="heading  heading-2">Bitte entschuldige.</h2>
              <p className="paragraph paragraph__text-center">Diese Seite konnten wir leider nicht finden</p>
            </div>
            <a href={'/'} className="link__cta margin-bottom-16" title="Zurück zur Startseite">
              Zurück zur Startseite
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFound;
