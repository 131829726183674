import React, { FC, useContext } from 'react';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container';

export type TFormViewProps = {
  children: React.ReactNode;
  className?: string;
};

export const FormView: FC<TFormViewProps> = ({ children, className = '' }) => {
  const { activeView } = useContext(InsuranceContainerContext);

  if(activeView !== INSURANCE_CONTAINER_VIEW.FORM) {
    return null;
  }

  return (
    <div className={`form-view ${className}`}>
      {children}
    </div>
  );
};
