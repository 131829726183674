import React from 'react';

const ResendSuccess = React.memo(() => {
  return (
    <p className="resend__paragraph">
      Vielen Dank. Dein Link wurde angefordert. Solltest du unter der
      angegebenen E-Mail Adresse einen Freischalt-Link gekauft haben, bekommst
      du eine E-Mail. Solltest du keine E-Mail bekommen, wende dich bitte an den
      Kunden-Support.
    </p>
  );
});

export default ResendSuccess;
