import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { asRem } from 'utils/asRem';

export default styled.div`
  height: ${asRem(20)};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding-right: ${asRem(30)};
  margin-top: ${asRem(10)};
  margin-bottom: ${asRem(30)};
  align-self: start;

  ${mediaQueries.lg} {
    padding-right: ${asRem(80)};
  }

  .icon {
    height: ${asRem(94)};

    ${mediaQueries.md} {
      height: ${asRem(95)};
    }
  }
`;
