import { combineEpics } from 'redux-observable';
import { applicationEpics } from './Application';
import { insuranceCooperationEpics } from './InsuranceCooperation';
import { userEpics } from './User';

export const rootEpics = combineEpics(
  applicationEpics,
  userEpics,
  insuranceCooperationEpics,
);
