import { createSlice } from '@reduxjs/toolkit';
import * as userReducers from 'Redux/Reducers/User';

export interface IUserState {
    currentUser: {
        uuid?: string,
        email: string,
    }|null,
    token: string|null,
    updatedAt: number,
    openRequests: string[],
    failedRequests: string[],
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: null,
        showOtpScreen: false,
        token: null,
        updatedAt: 0,
        openRequests: [],
        failedRequests: [],
    },
    reducers: userReducers,
});
