import { FunctionComponent } from 'react';
import { Section, BgColorContainer } from 'View/Component';
import { Button, Col, Headline, Image, Paragraph, Row } from 'View/Common';
import ZppAppSectionBackground from 'img/ZppAppSectionBackground';
import ZppApp from 'img/ZppAppImage';


interface IProps {
  startNowButton?: () => void;
}

export const AppDescriptionSection: FunctionComponent<IProps> = ({ startNowButton }) => {
  return (
    <BgColorContainer>
      <Section className="app-description-section">
        <Image
          className="app-section-background"
          image={ZppAppSectionBackground.src}
          srcSet={ZppAppSectionBackground.srcSet}
          alt="Luna schlafend mit Erstattungscheck in der Hand"
        />
        <Row gutter={16}>
          <Col xs={24} sm={16} md={11} className="text-left">
            <Headline level={2}>
              Mit dem speziell konzipierten Kurs "Erholsamen Schlaf finden – Ein App-gestützter Online-Kurs"
              sorgst du für mehr Gesundheit – bei Tag und bei Nacht.
            </Headline>
            <Paragraph>
              In 7 Kurswochen teilt 7Schläfer Schlafexperte Dr. Albrecht Vorster sein Wissen mit dir.
              Du erfährst, wie du erholsamen Schlaf mit gesunden Gewohnheiten und wirksamen Routinen förderst.
              Die kurzen Einheiten sind einfach verständlich und du kannst die Tipps und Tricks sofort in deinem
              Alltag umsetzen.
            </Paragraph>
            <Paragraph>
              7Schläfer kooperiert mit gesetzlichen Krankenkassen.
              Je nach Krankenkasse können bis zu 100% der Kosten für 7Schläfer erstattet werden.
            </Paragraph>
            <Button onClick={startNowButton} title="Gehe zum Registrierformular">Jetzt loslegen</Button>
          </Col>
          <Col xs={24} sm={8} md={{ offset: 2, span: 9 }} lg={{ offset: 4, span: 7 }}>
            <Image image={ZppApp.src} srcSet={ZppApp.srcSet} alt="IPhone mit Bild der 7 Schläferapp" />
          </Col>
        </Row>
      </Section>
    </BgColorContainer>
  );
};
