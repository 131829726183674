import React, { FunctionComponent } from 'react';
import { Modal } from 'View/Component';
import { Bold, Headline, Paragraph } from 'View/Common';

interface IProps {
  open?: boolean;
  onClose?: () => void;
}

export const HealthBenefitsModal: FunctionComponent<IProps> = ({ open, onClose }) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={<Headline level={2}>Gesundheitlicher Nutzen</Headline>}
    >
      <Paragraph>
        In diesem Angebot kommen kognitiv verhaltensorientierte Methoden zum Einsatz, deren
        Wirksamkeit in Bezug auf insomnische Beschwerden, Störungen beim Einschlafen, Durchschlafen,
        Früherwachen oder einer schlechten Schlafqualität bereits in vielen Studien nachgewiesen
        wurden (Irwin, Cole & Nicassio, 2006; Van Straten et al., 2018; Mitchell et al., 2012;
        Riemann et al., 2017; Van der Zweerde et al, 2019; Montgomery & Dennis, 2003).
      </Paragraph>
      <Paragraph>
        Die Forschung zeigt auch, dass sich Maßnahmen zur Verbesserung des Schlafes erfolgreich auf
        digitalem Wege vermitteln lassen (Hasan et al., 2022; Soh et al., 2020; Ye et al., 2016;
        Seyffert et al., 2016; Zachariae et al., 2016; Cheng et al., 2012). Das Angebot hat keinen
        Anspruch darauf, eine kognitive Verhaltenstherapie zu ersetzen. Es ist ein präventives
        Angebot, das Nutzende zu einem verbesserten Schlaf begleitet, um einer Entwicklung von
        Schlafstörungen präventiv vorzubeugen.
      </Paragraph>
      <Paragraph>
        Der gesundheitliche Nutzen des konkreten Kurses „Erholsamen Schlaf finden“ in der
        7Schläfer-App wurde bisher noch in keiner wissenschaftlichen Studie nachgewiesen. Wir planen
        daher bis 2025 eine Untersuchung durchzuführen. Wir erwarten, dass der Kurs zu einer
        signifikanten Verbesserung der Art und Schwere von insomnischen Beschwerden beiträgt. Dies
        äußert sich über die Zufriedenheit mit dem aktuellen Schlafmuster, die Beeinträchtigung der
        Lebensqualität und Leistungsfähigkeit durch das Schlafproblem, und die mit der
        Schlaflosigkeit verbundene Belastung.
      </Paragraph>
      <Paragraph>
        Nähere Informationen zur geplanten Studie sowie deren Präregistrierung findest du hier:
        https://osf.io/yj2va
      </Paragraph>
      <Paragraph>
        <Bold>Referenzen</Bold>
        <br />
        Van Straten, A., van der Zweerde, T., Kleiboer, A., Cuijpers, P., Morin, C. M., & Lancee, J.
        (2018). Cognitive and behavioral therapies in the treatment of insomnia: a meta-analysis.
        Sleep medicine reviews, 38, 3-16.
      </Paragraph>
      <Paragraph>
        Riemann, D., Baglioni, C., Bassetti, C., Bjorvatn, B., Dolenc Groselj, L., Ellis, J. G., ...
        & Spiegelhalder, K. (2017). European guideline for the diagnosis and treatment of insomnia.
        Journal of sleep research, 26(6), 675-700.
      </Paragraph>
      <Paragraph>
        Mitchell, M. D., Gehrman, P., Perlis, M., & Umscheid, C. A. (2012). Comparative
        effectiveness of cognitive behavioral therapy for insomnia: a systematic review. BMC family
        practice, 13(1), 1-11.
      </Paragraph>
      <Paragraph>
        Irwin, M. R., J. C. Cole & P. M. Nicassio (2006). Comparative meta-analysis of behavioral
        interventions for insomnia and their efficacy in middle-aged adults and in older adults 55+
        years of age. Health Psychol, 25(1), S. 3–14. doi: 10.1037/0278-6133.25.1.3.
      </Paragraph>
      <Paragraph>
        Van der Zweerde, T., L. Bisdounis, S. D. Kyle, J. Lancee & A. van Straten, A. (2019).
        Cognitive behavioral therapy for insomnia: A meta-analysis of long-term effects in
        controlled studies. Sleep Med Rev, 48, S. 101208. doi: 10.1016/j.smrv.2019.08.002. Epub 2019
        Aug 12. PMID: 31491656.
      </Paragraph>
      <Paragraph>
        Montgomery, P. & J. Dennis. Cognitive behavioural interventions for sleep problems in adults
        aged 60+ (2003). Cochrane Database Syst Rev, 1, CD003161. Doi: 10.1002/14651858.CD003161.
      </Paragraph>
      <Paragraph>
        Hasan, F., Tu, Y. K., Yang, C. M., Gordon, C. J., Wu, D., Lee, H. C., ... & Chiu, H. Y.
        (2022). Comparative efficacy of digital cognitive behavioral therapy for insomnia: a
        systematic review and network meta-analysis. Sleep medicine reviews, 61, 101567.
      </Paragraph>
      <Paragraph>
        Soh, H. L., Ho, R. C., Ho, C. S., & Tam, W. W. (2020). Efficacy of digital cognitive
        behavioural therapy for insomnia: a meta-analysis of randomised controlled trials. Sleep
        medicine, 75, 315-325.
      </Paragraph>
      <Paragraph>
        Ye, Y. Y., Chen, N. K., Chen, J., Liu, J., Lin, L., Liu, Y. Z., ... & Jiang, X. J. (2016).
        Internet-based cognitive–behavioural therapy for insomnia (ICBT-i): a meta-analysis of
        randomised controlled trials. BMJ open, 6(11), e010707.
      </Paragraph>
      <Paragraph>
        Seyffert, M., Lagisetty, P., Landgraf, J., Chopra, V., Pfeiffer, P. N., Conte, M. L., &
        Rogers, M. A. (2016). Internet-delivered cognitive behavioral therapy to treat insomnia: a
        systematic review and meta-analysis. PloS one, 11(2), e0149139.
      </Paragraph>
      <Paragraph>
        Zachariae, R., Lyby, M. S., Ritterband, L. M., & O'Toole, M. S. (2016). Efficacy of
        internet-delivered cognitive-behavioral therapy for insomnia–a systematic review and
        meta-analysis of randomized controlled trials. Sleep medicine reviews, 30, 1-10.
      </Paragraph>
      <Paragraph>
        Cheng, S. K., & Dizon, J. (2012). Computerised cognitive behavioural therapy for insomnia: a
        systematic review and meta-analysis. Psychotherapy and psychosomatics, 81(4), 206-216.
      </Paragraph>
    </Modal>
  );
};
