import AppBg from './schlafen-1-thumb.png';
import AppBg2x from './schlafen-1-thumb@2x.png';
import AppBg3x from './schlafen-1-thumb@3x.png';

export default {
  src: AppBg,
  srcSet: {
    '2x': AppBg2x,
    '3x': AppBg3x
  }
};
