import React from 'react';

type IProps = {
  href: string;
  children?: React.ReactNode;
  title?: string;
};

export const ExternalLink = ({ href, children, title = '' }: IProps) => {
  return (
    <a target="_blank" rel="noopener noreferrer" title={title} href={href}>
      {children}
    </a>
  );
};

