import { rehydrateStore, Store } from 'Redux/Config';
import Actions from 'Redux/Actions';

async function hydrateStore() {
  const state: any = Store.getState();
  if (state._persist && state._persist.rehydrated && state.hasOwnProperty('application') && state.application.isInitialized) {
    // here can be checked for reload or other things
    return false;
  }
  await rehydrateStore(Store);

  Store.dispatch(Actions.applicationBootstrapped());

  return true;
}

export default hydrateStore;
