import styled from 'styled-components';
import { colors } from 'utils/colors';
import { asRem } from 'utils/asRem';

export default styled.hr `
  border: solid ${asRem(1)} ${colors.blue.c030};
  margin-bottom: ${asRem(16)};
  margin-top: 0;

`;
