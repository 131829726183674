import { NavbarBrand, NavLink } from '@missionme/mime_elements';
import Style from './SleepBrandLink.style';
import { ROUTES } from 'utils/typings/routes';
import { Link } from '@reach/router';

export const SleepBrandLink = () => (
  <Style>
    <NavbarBrand
      tag={() => (
        <NavLink className="navbar-sleep-brand p-0" tag={Link} to={ROUTES.HOME}>
          7Schläfer Home
        </NavLink>
      )}
    >
      Logo
    </NavbarBrand>
  </Style>
);
