import React, { FunctionComponent, useState } from 'react';
import Style from './Modal.style';
import { asRem } from 'utils/asRem';
import { Scrollbar } from 'react-scrollbars-custom';

interface IProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}
export const Modal: FunctionComponent<IProps> = ({
  title,
  children,
  className,
  style,
  open,
  onClose,
  onCancel,
  onSubmit
}) => {

  const [visible, setVisible] = useState<boolean>(open ?? false);

  const handleOnOk = () => {
    onSubmit && onSubmit();
    setVisible(false);
    onClose && onClose();
  };

  const handleOnCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    onClose && onClose();
  };

  return (
    <Style
      title={title}
      open={open ?? visible}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      style={style}
      className={className}
      footer={null}
      width={asRem(940)}
    >
      <Scrollbar>
        {children}
      </Scrollbar>
    </Style>
  );
};
