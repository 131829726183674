import React, { FunctionComponent } from 'react';
import Style from './ZppLandingPage.style';
import { Menu } from 'View/Component';
import { HeaderSection, AppDescriptionSection, RefundCheckFormSection, HowToSection } from './Sections';
import { RouteComponentProps } from '@reach/router';

export const ZppLandingPage: FunctionComponent<RouteComponentProps> = () => {
  const formRef = React.useRef<HTMLElement>(null);

  const scrollToFormSection = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Style>
      <Menu noNavigation />
      <HeaderSection startNowButton={scrollToFormSection} />
      <AppDescriptionSection startNowButton={scrollToFormSection} />
      <RefundCheckFormSection ref={formRef}  />
      <HowToSection startNowButton={scrollToFormSection} />
    </Style>
  );
};
