import styled from 'styled-components';
import { asRem } from 'utils/asRem';

export default styled.main `
  
  .offer-wrapper {
    max-width: ${asRem(696)};
    
    & > .ant-col {
      justify-content: center;
      align-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
`;
