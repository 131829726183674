import React from 'react';

interface Props {
  // eslint-disable-next-line no-unused-vars
  refClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  data: {
    claim: string;
    duration: string;
  };
  children: React.ReactNode;
}

const ExplanationCode = (props: Props) => {
  const { claim, duration } = props.data;
  const { children } = props;
  return (
    <div className="wrapper">
      <div className="explanation-code__section ta-center">
        <h2 className="heading explanation-code__heading-2-b2b align-self-center">
          {claim}
        </h2>
        <div className="article__wrapper-b2b">
          <article className="article">
            <div className="article__image article__image--dein-code">
              <span className="visuallyhidden">Illustration: Dein Code</span>
            </div>
            <div className="article__image article__image--dein-code-mobile">
              <span className="visuallyhidden">Illustration: Dein Code</span>
            </div>
            <h3 className="heading heading-3 ta-center">Dein Code</h3>

            <p className="article__paragraph">
              {children}
            </p>
          </article>

          <article className="article">
            <div className="article__image article__image--code-einloesen">
              <span className="visuallyhidden">Illustration: Registrieren</span>
            </div>
            <h3 className="heading heading-3 ta-center">Code einlösen</h3>
            <p className="article__paragraph">
              Registriere dich in der 7Schläfer App und löse den Code dann
              <>
                &nbsp;
                <a
                  rel="noopener noreferrer"
                  href=""
                  onClick={(e) => props.refClick(e)}
                  title="Link zur Registrier-Seite"
                >
                  hier
                </a>
                &nbsp;
              </>
              ein, um die App für {duration} freizuschalten.
            </p>
          </article>

          <article className="article">
            <div className="article__image article__image--besser-schlafen">
              <span className="visuallyhidden">
                Illustration: Besser schlafen
              </span>
            </div>
            <h3 className="heading heading-3 ta-center">Besser schlafen</h3>
            <p className="article__paragraph">
              Der kostenlose Zugriff umfasst den gesamten Premium-Bereich der
              7Schläfer App.
            </p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ExplanationCode;
