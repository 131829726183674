import React, { FunctionComponent } from 'react';
import Style from './DarkContentBox.style';

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
export const DarkContentBox: FunctionComponent<IProps> = ({ children, ...props }) =>
  <Style { ...props }>{ children }</Style>;
