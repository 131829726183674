import Markdown from 'markdown-to-jsx';
import React, { FC } from 'react';
import { StaticPage } from 'api/graphql';

interface Props {
  data: StaticPage['markdown'];
  inModal?: boolean;
}

const MarkdownRenderer: FC<Props> = ({ data, inModal = false }) => {
  return (
    <Markdown
      options={{
        overrides: {
          h1: {
            props: {
              className: inModal
                ? 'heading heading-2 ta-left margin-top-10 margin-bottom-10 word-break--word'
                : 'heading heading-1 ta-left margin-top-50 margin-bottom-16 word-break--word'
            }
          },
          h2: {
            props: {
              className: 'heading heading-2 ta-left word-break--word'
            }
          },
          h3: {
            props: {
              className: 'heading heading-3 ta-left'
            }
          },
          h4: {
            props: {
              className: 'heading heading-4 ta-left'
            }
          },
          h5: {
            props: {
              className: 'heading heading-5 ta-left'
            }
          },
          p: {
            props: {
              className: 'paragraph ta-left'
            }
          },
          a: {
            props: {
              className: 'link__markdown'
            }
          },
          blockquote: {
            props: {
              className: 'blockquote__markdown'
            }
          },
          div: {
            props: {
              className: 'markdown__div padding-bottom-100'
            }
          },
          ul: {
            props: {
              className: 'markdown__list markdown__ul'
            }
          },
          ol: {
            props: {
              className: 'markdown__list markdown__ol'
            }
          },
          li: {
            props: {
              className: 'markdown__list markdown__li'
            }
          }
        }
      }}
    >
      {data}
    </Markdown>
  );
};

export default MarkdownRenderer;
