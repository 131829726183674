import { logoGJ, logoHansefit, logoRTL, logoXing } from 'img/PartnerLogosSlider';

export const businessCooperations = [
  {
    name: 'G+J',
    cooperationLogo: logoGJ,
  },
  {
    name: 'Xing',
    cooperationLogo: logoXing,
  },
  {
    name: 'Hansefit',
    cooperationLogo: logoHansefit,
  },
  {
    name: 'RTL',
    cooperationLogo: logoRTL,
  },
];
