import React, { FunctionComponent, useState } from 'react';
import { BgStars, Section, HealthBenefitsModal } from 'View/Component';
import { Button, Col, Headline, Image, Paragraph, Row } from 'View/Common';
import ZppSleepingLuna from 'img/ZppSleepingLuna';

interface IProps {
  startNowButton?: () => void;
}

export const HeaderSection: FunctionComponent<IProps> = ({ startNowButton }) => {
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  const toggleModal = (visible: boolean) => () => {
    setModalIsVisible(visible);
  };

  return (
    <BgStars className="stars-background">
      <HealthBenefitsModal open={modalIsVisible} onClose={toggleModal(false)} />
      <Section className="header-section">
        <Row gutter={16}>
          <Col sm={24} md={11}>
            <Image
              className="sleeping-luna-image"
              image={ZppSleepingLuna.src}
              srcSet={ZppSleepingLuna.srcSet}
              alt="Luna schlafend mit Erstattungscheck in der Hand"
            />
          </Col>
          <Col sm={24} md={13} className="center content-area">
            <Headline level={1}>Erholsamen Schlaf finden</Headline>
            <Paragraph className="margin-bottom-48">Ein App-gestützter Online-Kurs</Paragraph>
            <Button title="Gehe zum Registrierformular" onClick={startNowButton}>
              Jetzt loslegen
            </Button>
            <Button title="öffnet ein Fenster mit dem gesundheitlichen Nutzen" onClick={toggleModal(true)} type={'primary'}>
              Gesundheitlicher nutzen
            </Button>
          </Col>
        </Row>
      </Section>
    </BgStars>
  );
};
