import { ROUTES } from 'utils/typings/routes';

export type TMenuEntry = { to: string, title: string };

export const MenuEntries: TMenuEntry[] = [
  { to: ROUTES.HOME, title: 'Home' },
  { to: ROUTES.ZPP_LANDING_PAGE, title: 'Krankenkasse' },
  { to: ROUTES.PRICING, title: 'Preise' },
  { to: ROUTES.CONTACT, title: 'Kontakt' }
];
