import { TRootState } from 'Redux/Reducers';
import { arrayContains } from 'utils';
import Actions from 'Redux/Actions';
import { isOtpFailed } from 'Redux/Queries';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container/InsuranceContainer/InsuranceContainerContext';

export const showFormScreen = (state: TRootState) => {
  if(
    (arrayContains(state.insuranceCooperation.failedRequests, [ Actions.attendInsuranceCooperationUserDataFailure.type ]) && !isOtpFailed(state)) ||
    arrayContains([ ...state.insuranceCooperation.failedRequests, ...state.user.failedRequests ], [
      Actions.registerWithInsuranceNumberFailure.type,
      Actions.resendOnetimePasswordFailure.type
    ])
  ) {
    return INSURANCE_CONTAINER_VIEW.ERROR;
  }

  return state.insuranceCooperation.showFormScreen;
};
