import Image1x from './schlafen-2-thumb.png';
import Image2x from './schlafen-2-thumb@2x.png';
import Image3x from './schlafen-2-thumb@3x.png';

export default {
  src: Image1x,
  srcSet: {
    '2x': Image2x,
    '3x': Image3x
  }
};
