import { useMemo } from 'react';
import { platform } from 'config';
import { COOPERATION_VOUCHER_CONFIG, COOPERATION_VOUCHER } from 'utils/Constants/CooperationsVoucherClients';

export const useCooperationVoucher = (cooperationKey: keyof typeof COOPERATION_VOUCHER) => {
  return useMemo(() => {
    const platformConfigKey = platform.isProduction ? 'production' : 'staging';

    const config = COOPERATION_VOUCHER_CONFIG[cooperationKey];
    if (!config) {
      console.warn(
        `Keine Kooperation gefunden für: ${cooperationKey}, bitte ergänze Production und Staging Voucher in der CooperationsVoucherClients.ts`,
      );
      return COOPERATION_VOUCHER_CONFIG.FALLBACK[platformConfigKey];
    }
    return config[platformConfigKey];
  }, [cooperationKey]);
};
