import React, { FunctionComponent, useMemo } from 'react';
import { Select } from 'View/Common';
import { useGetInsuranceCompaniesQuery } from 'api/graphql';
import { LoadingSpinner } from 'View/Common';


export interface IHealthInsuranceEntry {
  __typename?: 'InsuranceCompany',
  name: string,
  reductionRate: number,
  cooperationUrl?: string | null,
  cooperationPartner?: boolean | null,
  cooperationLogo?: string | null
}

interface IProps {
  onChange?: (healthInsuranceEntry: IHealthInsuranceEntry) => void;
  className?: string;
  style?: React.CSSProperties;
}

type TSelectOption = { value: string, label: string, entry: IHealthInsuranceEntry };
type TCooperationObject = { cooperations: TSelectOption[], others: TSelectOption[] }

export const HealthInsuranceSelect: FunctionComponent<IProps> = ({ className, style, onChange }) => {

  const { loading, data } = useGetInsuranceCompaniesQuery();
  const { insuranceCompanies } = data || {};

  const orderedInsuranceCompanies: TCooperationObject = useMemo(() => {
    if(!insuranceCompanies) {
      return { cooperations: [], others: []};
    }
    return [ ...insuranceCompanies ].sort((a, b) => a.name.localeCompare(b.name)).reduce((acc: TCooperationObject, entry: IHealthInsuranceEntry) => {
      if(entry.cooperationPartner) {
        acc.cooperations.push({ value: `cooperations-${acc.cooperations.length}`, label: entry.name, entry });
      } else {
        acc.others.push({ value: `others-${acc.others.length}`, label: entry.name, entry });
      }
      return acc;
    }, { cooperations: [], others: []});
  }, [insuranceCompanies]);

  const handleOnChange = (value: string) => {
    const [type, index] = value.split('-') as [keyof TCooperationObject, number];

    if(!insuranceCompanies || orderedInsuranceCompanies[type].length <= Number(index)) {
      return;
    }
    onChange && onChange(orderedInsuranceCompanies[type][index].entry);
  };

  if(loading || !insuranceCompanies) {
    return <LoadingSpinner />;
  }

  const { cooperations, others } = orderedInsuranceCompanies;

  return (
    <Select
      style={style}
      className={className}
      onChange={handleOnChange}
      options={!!cooperations.length ? [{ label: 'Krankenkassen ohne Vorauszahlung', options: cooperations }, { label: 'Alle Krankenkassen', options: others }] : others}
      placeholder={'Wähle deine Krankenkasse'}
    />
  );
};

