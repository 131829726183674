import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { DarkContentBox } from 'View/Component';
import { colors } from 'utils/colors';
import { mediaQueries } from '../../../utils/Constants/Breakpoints';

export default styled(DarkContentBox)`
  position: relative;
  width: ${asRem(340)};
  height: ${asRem(205)};
  padding: ${asRem(26)} ${asRem(16)} ${asRem(16)};
  margin-bottom: ${asRem(16)};
  border-color: ${colors.blue.c030};
  
  .body {
    margin-top: 0;

    ${mediaQueries.md} {
      margin-top: ${asRem(10)};
    }
  }
  
  ${mediaQueries.md} {
    height: ${asRem(212)};
    margin-bottom: ${asRem(8)};
  }
  
  &.highlighted {
    padding-top: ${asRem(36)};
    height: ${asRem(234)};

    ${mediaQueries.md} {
      height: ${asRem(220)};
    }

    .offer-label {
      top: ${asRem(32)};
    }
    .price-label {
      padding-top: ${asRem(4)};
    }

    .price-information .crossed-out-price {
      top: ${asRem(20)};

      ${mediaQueries.md} {
        top: ${asRem(-32)};
      }
    }
  }

  .offer-label {
    position: absolute;
    top: ${asRem(18)};
    left: 0;
    width: 100%;
    text-align: center;

    ${mediaQueries.md} {
      position: static;
      top: auto;
      left: auto;
      width: auto;
    }
    
  }

  .box-badge {
    border-top-left-radius: ${asRem(8)};
    border-top-right-radius: ${asRem(8)};
    background-color: ${colors.blue.c030};
    font-family: "Source Sans Pro";
    color: ${colors.beige.c010};
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    letter-spacing: ${asRem(0.3)};
    text-align: center;
    width: 100%;
    height: ${asRem(24)};
    line-height: ${asRem(24)};
  }
  
  .price-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .crossed-out-price {
      position: absolute;
      left: 0;
      right: 0;
      top: ${asRem(16)};
      font-size: ${asRem(16)};
      color: ${colors.error.c050};
      text-decoration: line-through;
    }
    
    .price-label {
      font-family: "Source Sans Pro";
      height: ${asRem(36)};
      margin-top: ${asRem(36)};
      margin-bottom: ${asRem(-8)};
      
      ${mediaQueries.md} {
        margin-bottom: ${asRem(16)};
        margin-top: ${asRem(-8)};
      }
    }

    
    .price-info-box {
      flex: 1;
      margin-bottom: ${asRem(8)};

      ${mediaQueries.md} {
        margin-top: ${asRem(8)};
        margin-bottom: 0;
      }
      
      span {
        display: block;
      }
    }
  }

  .cta-wrapper {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    h2 {
      margin-bottom: ${asRem(8)};
      font-size: ${asRem(24)};
    }
    
    button {
      width: ${asRem(200)};
      padding: ${asRem(15)} ${asRem(40)};
      margin-top: ${asRem(8)};
      margin-bottom: 0;
      min-width: auto;
      background-image: linear-gradient(to right, ${colors.blue.c020} 30%, ${colors.blue.c040} 160%);
      
      &:hover {
        box-shadow: inset 0 0 0 ${asRem(1)} ${colors.blue.c060};
      }

      ${mediaQueries.md} {
        height: ${asRem(33)};
        line-height: ${asRem(4)};
      }
    }
  }
  
  ${mediaQueries.md} {
    height: ${asRem(146)};

    &.highlighted {
      height: ${asRem(172)};
      padding-top: ${asRem(46)};
    }
    
    .cta-wrapper {
      button {
        width: ${asRem(160)};
        padding: ${asRem(15)} ${asRem(28)};
      }
    }
    
    .price-information {
      text-align: left;

      .crossed-out-price {
        left: ${asRem( 8)};
        top: -${asRem(32)};
      }
    }
  }
`;
