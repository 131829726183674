export const isValidBirthday = (dateString: string): boolean => {
  // Überprüfe das Format (TT.MM.JJJJ)
  const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
  const match = dateString.match(regex);
  if (!match) return false;

  // Extrahiere Tag, Monat und Jahr
  const [, day, month, year] = match.map(Number);

  // Überprüfe die Gültigkeit des Datums
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
    return false;
  }

  // Überprüfe, ob das Datum in der Vergangenheit liegt
  const today = new Date();
  if (date > today) return false;

  // Überprüfe, ob das Alter realistisch ist (z.B. nicht älter als 120 Jahre)
  const maxAge = 120;
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - maxAge);
  if (date < minDate) return false;

  return true;
};
