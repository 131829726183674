import styled from 'styled-components';
import { asRem } from 'utils';
import { Section } from 'View/Container';
import { breakpoints } from 'View/Theme';

export const CooperationStyle = styled(Section).attrs({
  className: 'health-cooperation-slider',
})`
  align-items: center;
  height: ${asRem(200)};

  @media (min-width: ${breakpoints.md}) {
    height: ${asRem(300)};
  }

  @media (min-width: ${breakpoints.lg}) {
    .container-fluid {
      margin: auto;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .mime-elements-headline-3 {
    width: 80%;
    margin: 0 auto ${asRem(20)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(400)};
      margin-bottom: ${asRem(25)};
    }

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(480)};
      margin-bottom: ${asRem(30)};
    }
  }

  .slick-dots {
    margin-bottom: ${asRem(-15)};

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: ${asRem(-10)};
    }
  }

  .logo {
    aspect-ratio: 180 / 90;
    width: ${asRem(140)};
    height: ${asRem(70)};
    margin: 0 ${asRem(4)};

    @media (min-width: ${breakpoints.md}) {
      width: ${asRem(180)};
      height: ${asRem(90)};
      margin: 0 ${asRem(10)};
    }

    @media (min-width: ${breakpoints.lg}) {
      width: ${asRem(160)};
      height: ${asRem(80)};
      margin: 0 ${asRem(10)};
    }
  }
`;
