import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';
import { asRem } from 'utils/asRem';

const generalStyle = `
    margin: 0;
    padding: 0;
    font-family: "Calluna", "Georgia", serif;
    font-weight: normal;
    font-variant: lining-nums;
    color: ${colors.beige.c010};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
`;

export const StyleH1 = styled.h1`
  ${generalStyle};

  font-size: ${asRem(40)};
  line-height: ${asRem(46)};

  ${mediaQueries.md} {
    font-size: ${asRem(60)};
    line-height: ${asRem(60)};
  }
`;

export const StyleH2 = styled.h2`
  ${generalStyle};

  font-size: ${asRem(24)};
  line-height: ${asRem(32)};
  margin-bottom: ${asRem(20)};

  ${mediaQueries.md} {
    font-size: ${asRem(36)};
    line-height: ${asRem(36)};
  }
`;

export const StyleH3 = styled.h3`
  ${generalStyle};

  font-size: ${asRem(22)};
  line-height: ${asRem(29)};
  margin-bottom: ${asRem(4)};

  ${mediaQueries.md} {
    font-size: ${asRem(26)};
    line-height: ${asRem(34)};
  }
`;

export const StyleH4 = styled.h4`
  ${generalStyle};

  font-size: ${asRem(18)};
  line-height: ${asRem(21)};
  margin-bottom: 0;

  ${mediaQueries.md} {
    font-size: ${asRem(22)};
    line-height: ${asRem(27)};
  }
`;

export const StyleH5 = styled.h5`
  ${generalStyle};
`;
