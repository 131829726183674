import React from 'react';

const BenefitsProJobs = () => {
  return (
    <section className="benefits-pro-jobs__section">
      <div className="wrapper__flex-col-row flex-basis-100">
        <div className="wrapper__flex-col flex-basis-50 padding-r-0-30">
          <h2 className="heading heading-2-b2b ta-left">
            Entdecke, wie guter Schlaf dein Leben verändert.
          </h2>
          <p className="paragraph">
            Ob im Berufsalltag, auf Jobsuche oder beim Netzwerken: Wer
            ausgeschlafen in den Tag startet, erhöht seine&nbsp;
            <span className="em">Chancen auf Erfolg.</span> Mit 7Schläfer wird
            dein Schlaf wieder zu der wichtigen Energiequelle, die er sein kann.
            Basierend auf den&nbsp;
            <span className="em">neusten Erkenntnissen der Wissenschaft</span>
            &nbsp; unterstützt dich die App beim Aufbau einer gesunden und
            nachhaltig effektiven Schlafroutine. Du erfährst, wie du Kopf und
            Körper in eine erholsame Nacht führst und lernst deine&nbsp;
            <span className="em">individuellen Bedürfnisse</span>&nbsp; kennen.
            Und auch für den Notfall bist du ausgestattet: Die 7Schläfer
            Schlafhilfen schicken deine schlaflosen Nächte ins Land der Träume.
          </p>
          <ul className="list">
            <li className="list__item-checkmark">
              7 Wochen Schlaf-Kurs mit Trainings zur Verbesserung des eigenen
              Schlafes
            </li>
            <li className="list__item-checkmark">
              Abend- und Morgenroutinen für einen neuen Rhythmus
            </li>
            <li className="list__item-checkmark">
              Umfangreiche Einschlaf-Bibliothek
            </li>
            <li className="list__item-checkmark">
              Dein individuelles Schlaf-Logbuch
            </li>
            <li className="list__item-plus">
              <div className="flex-col">
                <span className="em">Exklusiv für XING ProJobs:</span> 12 Monate
                kostenloser Zugang (6 Monate bei XING Premium)
              </div>
            </li>
            <li className="list__item-plus">
              <div className="flex-col">
                <span className="em">2 online Live-Events</span> mit
                Schlafforscher und Biologe Albrecht Vorster (inkl. offener
                Fragerunde) zu den Themen "Schlaf & Erfolg", sowie "Schlaf & New
                Work"
              </div>
            </li>
          </ul>
        </div>
        <div className="wrapper__flex-col flex-basis-50 padding-l-0-30">
          <div className="benefits-pro-jobs__image-app-screens">
            <span className="visuallyhidden">Bild: App Screens</span>
          </div>
          <div className="benefits-pro-jobs__info-box">
            <h2 className="heading heading-3 ta-left">Noch gut zu wissen:</h2>
            <p className="paragraph">
              Die 7Schläfer-App gibt's auch beim Vorteilsprogramm von XING
              Premium. Das Premium-Angebot enthält 6 Monate Vollzugriff, das
              ProJobs-Angebot 12 Monate. Schließt Du während der
              Premium-Laufzeit das Angebot auch über ProJobs ab, gilt ab diesem
              Zeitpunkt die neue Laufzeit von 12 Monaten.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsProJobs;
