import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Header from 'components/Header';
import { BrowserView, MobileView } from 'react-device-detect';
import HowToContinueDesktop from 'components/HowToContinueDesktop';
import HowToContinueMobile from 'components/HowToContinueMobile';

import steps1 from 'img/icon_01.svg';
import steps2 from 'img/icon_02.svg';
import steps3 from 'img/icon_03.svg';
import steps4 from 'img/icon_04.svg';

//////////////////////////////////////////////////////////////////////////////////////

const dataDesktop = {
  mainHeadline: 'Fast geschafft',
  mainSubline: 'Du hast einen Freischalt-Link per E-Mail bekommen',
  headline: "Wie geht's jetzt weiter?",
  items: [
    {
      text: 'Wir haben dir einen Freischalt-Link an die beim Einlösen des Codes angegebene E-Mail Adresse geschickt.',
      icon: steps1,
    },
    {
      text: 'Lade die App aus dem Appstore und registriere dich, falls du es nicht bereits hast.',
      icon: steps2,
    },
    {
      text: 'Öffne den Freischalt-Link aus der E-Mail auf deinem Mobiltelefon.',
      icon: steps3,
    },
    {
      text: 'Alle Inhalte des 7Schläfer Programms werden in der App für dich freigeschaltet.',
      icon: steps4,
    },
  ],
  contactMail: 'partner@7schlaefer.app',
};

/////////////////////////////////////////////////////////////////////////////////////////

const dataMobile = {
  mainHeadline: 'Fast geschafft',
  mainSubline: 'Du hast einen Freischalt-Link per E-Mail bekommen',
  textStep1: 'Lade die App herunter und registriere dich, falls du es nicht bereits hast.',
  textStep2: 'Öffne diesen Freischalt-Link. Denselben haben wir dir ebenfalls per E-Mail geschickt.',
  textStep3: 'Alle Inhalte sind nun in der App für dich freigeschaltet. Bei Fragen melde dich bei uns unter',
  email: 'partner@7schlaefer.app',
};

//////////////////////////////////////////////////////////////////////////////////////

const RedeemVucherSuccess: React.FC<RouteComponentProps> = (props) => {
  const { path } = props;

  return (
    <>
      <Header noNavigation path={path} />
      <main>
        <BrowserView>
          <HowToContinueDesktop data={dataDesktop} />
        </BrowserView>
        <MobileView>
          <HowToContinueMobile data={dataMobile} hasVoucherToken />
        </MobileView>
      </main>
    </>
  );
};

export default RedeemVucherSuccess;
