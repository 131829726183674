import styled from 'styled-components';
import { colors } from 'utils/colors';
import { asRem } from 'utils/asRem';

export default styled.div `
  background-color: ${colors.blue.c070};
  padding: ${asRem(32)} ${asRem(24)};
  border-radius: ${asRem(8)};
  border-bottom: solid ${asRem(4)} ${colors.blue.c060};
`;
