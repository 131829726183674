import styled from 'styled-components';
import { Card } from 'antd';
import { asRem } from 'utils/asRem';
import { colors } from 'utils/colors';

export default styled(Card) `
  background-color: ${colors.blue.c060};
  border: solid ${asRem(1)} ${colors.blue.c030};
  
  .ant-card-head {
    background-color: ${colors.blue.c030};
    border-bottom: none;
  }
  
  p {
    margin: 0 0 ${asRem(15)};
  }
`;
