import { TRootState } from 'Redux/Reducers';

type TAttendMetaData = {
  insuranceNumber?: string;
  dateOfBirth?: string;
  gender?: 'männlich'|'weiblich'|'divers';
  age?: number;
};


export const getUserMetaData = (state: TRootState) => {
  const formData = state.insuranceCooperation.form?.user || {};
  const metaData: TAttendMetaData = {};

  Object.keys(formData).forEach((key) => {
    // @ts-ignore
    const value = formData[key];
    if(!`${value || ''}`.trim()) {
      return;
    }

    switch(key) {
      case 'insuranceNumber' :
        metaData.insuranceNumber = value;
        break;
      case 'dateOfBirth' :
        metaData.dateOfBirth = value;
        break;
      case 'gender' :
        metaData.gender = value;
        break;
      case 'age' :
        metaData.age = parseInt(`${value}`, 10);
        break;
    }

  });
  return metaData;
};
