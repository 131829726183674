import React from 'react';

export const LoadingSpinner = () => {
  return (
    <div className="loading-spinner__wrapper">
      <div className="loading-spinner" role="status">
        <span className="visuallyhidden">Lädt</span>
      </div>
    </div>
  );
};
