import React, { FunctionComponent } from 'react';
import Style from './TextBadge.style';

interface IProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}
export const TextBadge: FunctionComponent<IProps> = ({ children, ...props }) => (
  <><Style {...props}>{children}</Style><div /></>
);
