import { asRem } from '@missionme/mime_elements';
import styled from 'styled-components';

export const StyledHomeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${asRem(0)};
`;
