import React from 'react';
import { StripeCardPaymentMethod } from 'api/graphql';

interface Props {
  data: StripeCardPaymentMethod;
}

const Card = React.memo((props: Props) => {
  const { card } = props.data;
  return (
    <>
      <div className="summary__label capitalize">Kreditkarte: {card.brand}</div>
      <div className="summary__label">
        Kartennummer: **** **** **** {card.last4}
      </div>
      <div className="summary__label">
        Ablaufdatum: {('0' + card.expMonth).slice(-2)}/{card.expYear}
      </div>
    </>
  );
  /* render using props */
});

export default Card;
