import React, { FC, ReactNode, useEffect, useState } from 'react';
import Style from './InsuranceContainer.style';
import { InsuranceContainerContext } from './InsuranceContainerContext';
import {
  Form,
  TFormProps,
  FormView,
  TFormViewProps,
  OtpView,
  TOtpViewProps,
  SuccessView,
  TSuccessViewProps,
  ErrorView,
} from 'View/Component/InsuranceForm/InsuranceContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceCooperationProvider, showFormScreen } from 'Redux/Queries';
import Actions from 'Redux/Actions';
import { useCooperationVoucher } from 'View/HookApi/useCooperationVoucher';
import { COOPERATION_VOUCHER } from 'utils/Constants/CooperationsVoucherClients';

type TProps = {
  cooperationVoucher: keyof typeof COOPERATION_VOUCHER;
  children: ReactNode;
} & {
  FormView?: FC<TFormViewProps>;
  Form?: FC<TFormProps>;
  OtpView?: FC<TOtpViewProps>;
  SuccessView?: FC<TSuccessViewProps>;
};

export const INSURANCE_CONTAINER_VIEW = {
  FORM: 'FORM',
  OTP: 'OTP',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;

export type TInsuranceContainerView = (typeof INSURANCE_CONTAINER_VIEW)[keyof typeof INSURANCE_CONTAINER_VIEW];

export function InsuranceContainer({ cooperationVoucher, children }: TProps) {
  const dispatch = useDispatch();

  const visibleFormScreen = useSelector(showFormScreen);
  const insuranceCooperationProvider = useSelector(getInsuranceCooperationProvider);

  const [activeView, setActiveView] = useState<TInsuranceContainerView>(INSURANCE_CONTAINER_VIEW.FORM);

  const b2bClientKey = useCooperationVoucher(cooperationVoucher);

  useEffect(() => {
    if (b2bClientKey !== insuranceCooperationProvider) {
      dispatch(Actions.clearHealthInsuranceUserData());
      setActiveView(INSURANCE_CONTAINER_VIEW.FORM);
      return;
    }

    switch (true) {
      case visibleFormScreen === INSURANCE_CONTAINER_VIEW.OTP && activeView === INSURANCE_CONTAINER_VIEW.FORM:
        setActiveView(INSURANCE_CONTAINER_VIEW.OTP);
        break;
      case visibleFormScreen === INSURANCE_CONTAINER_VIEW.SUCCESS && activeView === INSURANCE_CONTAINER_VIEW.OTP:
        setActiveView(INSURANCE_CONTAINER_VIEW.SUCCESS);
        break;
    }
  }, [visibleFormScreen, b2bClientKey, insuranceCooperationProvider]);

  return (
    <InsuranceContainerContext.Provider value={{ b2bClientKey, activeView }}>
      <Style className="insuranceContainer">{visibleFormScreen === INSURANCE_CONTAINER_VIEW.ERROR ? <ErrorView /> : children}</Style>
    </InsuranceContainerContext.Provider>
  );
}

InsuranceContainer.FormView = FormView;
InsuranceContainer.Form = Form;
InsuranceContainer.OtpView = OtpView;
InsuranceContainer.SuccessView = SuccessView;
