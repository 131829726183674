import { FormikErrors, FormikHandlers, FormikTouched, FormikValues, useFormik } from 'formik';
import  { FC, ReactNode, useCallback, useMemo, useContext, useRef } from 'react';
import { insuranceFormRequiredFields } from 'utils/Constants/InsuranceFormRequiredFields';
import ErrorFocus from 'utils/Formik_Error_Focus';
import { isValidEmail, isValidInsuranceNumber } from 'utils/validations';
import { Button, TFormFieldProps } from 'View/Common';
import { HealthPreConditionsLabel, LegalConditionLabel } from 'View/Component';
import { useDispatch, useSelector } from 'react-redux';
import { toIsoDate } from 'utils';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import Actions from 'Redux/Actions';
import { TRegisterWithInsuranceNumberRequestPayload } from 'Redux/Reducers/InsuranceCooperation';
import { isInsuranceCooperationLoading } from 'Redux/Queries';
import { isValidBirthday } from 'utils/validations/isValidBirthday';

interface IRegister {
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

interface IFormChildrenProps {
  register: (name: string, options?: { required?: boolean }) => TFormFieldProps;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
}

export type TFormProps = {
  className?: string;
  children: ({ register, values, errors, touched }: IFormChildrenProps) => ReactNode;
  defaultValues?: Record<string, unknown>;
  additionalFields?: string[];
  insuranceName?: string;
};

export const Form: FC<TFormProps> = ({
  defaultValues = {},
  additionalFields = [],
  className = '',
  children,
  insuranceName = '(#Krankenkassenname)',
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isInsuranceCooperationLoading);
  const { b2bClientKey } = useContext(InsuranceContainerContext);

  const _fields = useMemo(() => {
    return [
      ...additionalFields,
      'name',
      'email',
      'insuranceNumber',
      'dateOfBirth',
      'healthPreconditions',
      'legalConditions',
      'dataTransfer',
      'noMoreCourses',
      'successfulCourse',
      'gender',
      'age',
    ];
  }, [additionalFields]);

  const requiredFieldsRef = useRef<Record<string, boolean>>({});

  const _register = useCallback(
    ({ handleChange, values, errors, handleBlur, touched }: IRegister) =>
      (name: string, options?: { required?: boolean }) => {
        const isRequired = options?.required ?? false;

        if (isRequired) {
          requiredFieldsRef.current[name] = true;
        }

        const fieldProps: Record<string, any> = {
          type: 'input',
          label: undefined as string | ReactNode,
          required: isRequired,
        };

        switch (name) {
          case 'name': {
            fieldProps.label = 'Name';
            fieldProps.placeholder = 'Vor- und Nachname*';
            break;
          }
          case 'email': {
            fieldProps.label = 'E-Mail';
            fieldProps.placeholder = 'E-Mail*';
            break;
          }
          case 'insuranceNumber': {
            fieldProps.label = 'Versichertennummer';
            fieldProps.placeholder = 'Versichertennummer*';
            break;
          }
          case 'dateOfBirth': {
            fieldProps.label = 'Geburtsdatum';
            fieldProps.placeholder = 'Geburtsdatum* (TT.MM.JJJJ)';
            break;
          }
          case 'age': {
            fieldProps.label = 'Alter';
            fieldProps.placeholder = 'Alter';
            break;
          }
          case 'gender': {
            fieldProps.label = 'Geschlecht';
            fieldProps.placeholder = 'Geschlecht';
            fieldProps.type = 'select';
            fieldProps.options = [
              { key: 'FEMALE', value: 'FEMALE', label: 'weiblich' },
              { key: 'MALE', value: 'MALE', label: 'männlich' },
              { key: 'DIVERS', value: 'DIVERS', label: 'divers' },
            ];
            break;
          }
          case 'healthPreconditions': {
            fieldProps.type = 'toggle';
            fieldProps.label = <HealthPreConditionsLabel />;
            break;
          }
          case 'legalConditions': {
            fieldProps.type = 'toggle';
            fieldProps.label = <LegalConditionLabel />;
            break;
          }
          case 'dataTransfer': {
            fieldProps.type = 'toggle';
            fieldProps.label =
              `Ich bin damit einverstanden, dass meine persönlichen Daten, die ich hier im Formular angegeben habe, sowie mein Kursfortschritt an die ${insuranceName} übermittelt werden.`;
            break;
          }
          case 'noMoreCourses': {
            fieldProps.type = 'toggle';
            fieldProps.label =
              'Hiermit bestätige ich, dass ich in diesem Kalenderjahr noch nicht an zwei Präventionskursen teilgenommen habe.';
            break;
          }
          case 'successfulCourse': {
            fieldProps.type = 'toggle';
            fieldProps.label =
              `Mir ist bewusst, dass die Übernahme der Kosten durch meine Krankenkasse ${insuranceName} auf einem erfolgreichen Kursabschluss beruht.`;
            break;
          }
        }

        return {
          id: name,
          name,
          value: values[name],
          errorMessage: touched[name] && errors?.[name] ? errors[name] : undefined,
          onChange: handleChange,
          onBlur: handleBlur,
          ...fieldProps,
        };
      },
    [insuranceName],
  );

  const validation = useCallback(
    (values: FormikValues): FormikErrors<FormikValues | undefined> => {
      const errors: FormikErrors<FormikValues> = {};

      _fields.forEach((field) => {
        if (requiredFieldsRef.current[field] && !values[field]) {
          errors[field] = insuranceFormRequiredFields[field] || `${field} wird benötigt.`;
        }

        if (values[field]) {
          switch (field) {
            case 'email': {
              if (!isValidEmail(values[field])) {
                errors[field] = 'E-Mail ist ungültig.';
              }
              break;
            }
            case 'insuranceNumber': {
              if (!isValidInsuranceNumber(values[field])) {
                errors[field] = 'Versichertennummer ist ungültig.';
              }
              break;
            }
            case 'dateOfBirth': {
              if (!isValidBirthday(values[field])) {
                errors[field] = 'Bitte gebe ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein.';
              }
              break;
            }
          }
        }
      });

      return Object.keys(errors).length > 0 ? errors : undefined;
    },
    [_fields],
  );

  const { values, errors, touched, isSubmitting, isValidating, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      handleOnSubmitForm(values);
    },
    validate: validation,
  });

  const handleOnSubmitForm = useCallback(
    (values: FormikValues) => {
      const _values = { ...values };
      Object.keys(_values).forEach((key) => {
        const value = _values[key];
        if (!value && defaultValues[key]) {
          _values[key] = defaultValues[key];
        }
      });

      const { name = '', email = '', insuranceNumber = '', ...userMetaData } = _values;
      const _userMetaData = { ...userMetaData };

      if (_values.dateOfBirth) {
        _userMetaData.dateOfBirth = toIsoDate(_values.dateOfBirth as string);
      }

      dispatch(Actions.setInsuranceProvider({ provider: b2bClientKey }));
      dispatch(
        Actions.registerWithInsuranceNumberRequest({
          user: { name, email, insuranceNumber },
          userMetaData: _userMetaData,
        } as TRegisterWithInsuranceNumberRequestPayload),
      );
    },
    [dispatch, b2bClientKey, defaultValues],
  );

  const isButtonDisabled = useCallback(() => {
    return isLoading || Object.values(errors).some((item) => item?.length) || !!validation(values);
  }, [isLoading, errors, values, validation]);

  return (
    <form className={`insurance-form ${className}`} onSubmit={handleSubmit}>
      {children({
        register: _register({ values, errors, touched, handleChange, handleBlur }),
        values,
        errors,
        touched,
      })}
      <div className="form-button">
        <Button disabled={isButtonDisabled()} htmlType="submit">
          Registrieren
        </Button>
      </div>
      <ErrorFocus isSubmitting={isSubmitting} isValidating={isValidating} errors={errors} />
    </form>
  );
};
