export function redirectToStoreDownload() {
  const userAgent = navigator.userAgent;
  const appStoreLink = 'https://apps.apple.com/de/app/7schl%C3%A4fer-die-schlaf-app/id1520389863';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.greenhouse.schlafen';

  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isMacOS = /Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent);
  const storeLink = isIOS || isMacOS ? appStoreLink : playStoreLink;

  const anchor = document.createElement('a');
  anchor.href = storeLink;
  anchor.target = '_blank';
  anchor.rel = 'noreferrer';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
