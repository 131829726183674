export * from './RegisterWithInsuranceNumberEpic/RegisterWithInsuranceNumberEpic';

import { combineEpics } from 'redux-observable';
import { registerWithInsuranceNumberEpic } from './RegisterWithInsuranceNumberEpic/RegisterWithInsuranceNumberEpic';
import { attendInsuranceCooperationUserDataEpic } from './AttendInsuranceCooperationUserDataEpic/AttendInsuranceCooperationUserDataEpic';

export const insuranceCooperationEpics = combineEpics(
  registerWithInsuranceNumberEpic,
  attendInsuranceCooperationUserDataEpic,
);
