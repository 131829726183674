// calculates included VAT

export default function calculateVat(
  amount: number,
  taxRatePercentage: number | null | undefined
): number | null {
  if (!amount || !taxRatePercentage) {
    return null;
  }

  const taxToNetFactor = 1 + taxRatePercentage / 100;
  const netPriceAs2DigitInteger = Math.round(amount / taxToNetFactor);
  const taxDeducted = (amount - netPriceAs2DigitInteger) / 100;

  return taxDeducted;
}
