import React from 'react';

export interface IIconBoxItem {
  text: string;
  icon: string;
}

interface IProps {
  children?: React.ReactNode;
  headline: string;
  items: IIconBoxItem[];
}

export const IconBox = ({ children, headline, items }: IProps) => {
  return (
    <div className="icon-box">
      <div className="box box__rounded">
        <div className="margin-bottom-33 margin-top-33 flex-column">
          <h2 className="heading heading-2">{headline}</h2>
        </div>
        <div className="flex-col-row">
          {items.map((item, index) => (
            <div key={index} className="icon-box__wrapper flex flex-column align-items-center">
              <img className="icon-box__icon" src={item.icon} />
              <p className="icon-box__paragraph ta-center">{item.text}</p>
            </div>
          ))}
        </div>
        {children ? children : <div className="icon-box__bottom-spacer" />}
      </div>
    </div>
  );
};
