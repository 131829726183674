import React, { forwardRef, FunctionComponent } from 'react';
import Style from './Section.style';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  ref?: React.LegacyRef<HTMLElement>;
}

export const Section: FunctionComponent<IProps> = forwardRef<HTMLElement, IProps>(({ children, className, style }, ref) => {
  return (
    <Style ref={ref} className={className} style={style}>{children}</Style>
  );
});
