import React from 'react';

interface Props {
  isDarkBlue?: boolean;
}

export const Footer_Branding = (props: Props) => {
  return (
    <div className={props.isDarkBlue ? 'footer footer__branding footer__branding--dark-blue' : 'footer footer__branding'}>
      <div className="footer__branding-wrapper">
        <h5 className="heading heading-5">7Schläfer wird empfohlen von:</h5>
        <div className="footer__logo-holder">
          <div className="footer__logo footer__logo--brigitte">
            <span className="visuallyhidden">Logo Brigitte</span>
          </div>
          <div className="footer__logo footer__logo--flow">
            <span className="visuallyhidden">Logo Flow</span>
          </div>
          <div className="footer__logo footer__logo--balloon">
            <span className="visuallyhidden">Logo Balloon</span>
          </div>
        </div>
      </div>
    </div>
  );
};
