import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { StaticPageName, useStaticPagesQuery } from 'api/graphql';
import MarkdownRenderer from 'components/MarkdownRenderer';
import Header from 'components/Header';

const Imprint: React.FC<RouteComponentProps> = (props) => {
  const { data, loading, error } = useStaticPagesQuery({
    variables: { name: StaticPageName.TermsAndConditions },
  });

  const { path } = props;

  if (error) {
    console.error(error.message);
  }

  return (
    <>
      <Header path={path} />
      <main className="main">
        <div className="wrapper padding-top-100 flex-column">{data && <MarkdownRenderer data={data.staticPages[0].markdown} />}</div>
      </main>
    </>
  );
};

export default Imprint;
