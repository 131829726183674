import React, { ReactNode } from 'react';
import Style from './CheckMarkSection.style';
import { FunctionComponent } from 'react';
import { Col, Row } from '@missionme/mime_elements';



export type TCheckMarkSectionProps = {
  children?: ReactNode,
  image?: ReactNode
}

export const CheckMarkSection: FunctionComponent<TCheckMarkSectionProps> = ({ children, image }) => {
  return (
    <Style>
      <Row>
        <Col xs={{ size: 12 }} md={{ size: 6 }}>
          {children}
        </Col>
        <Col xs={{ size: 12 }} md={{ size: 5 }} lg={{ size: 5 }} className="offset-md-1 position-relative">
          {image}
        </Col>
      </Row>
    </Style>
  );
};
