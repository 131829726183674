import { RouteComponentProps } from '@reach/router';
import React, { useContext } from 'react';
import StoreButtons from 'components/StoreButtons';
import { Store } from 'utils/store';

interface Props {
  data: {
    mainHeadline: string;
    mainSubline: string;
    textStep1: string;
    textStep2: string;
    textStep3: string;
    email?: string;
  };
  hasVoucherToken?: boolean;
  hasPurchaseToken?: boolean;
}

type IProps = RouteComponentProps & Props;

const HowToContinueMobile: React.FC<IProps> = ({
  data,
  hasVoucherToken,
  hasPurchaseToken
}) => {
  const [store] = useContext(Store);
  const { purchase, redeemVoucher } = store;

  const { mainHeadline, mainSubline, textStep1, textStep2, textStep3, email } =
    data;
  return (
    <>
      <main className="main main--no-footer-cta padding-l-0 padding-r-0">
        <div className="flex-column padding-top-100 margin-bottom-50">
          <div className="padding-l-15 padding-r-15">
            <h1 className="heading heading-1 margin-bottom-8">
              {mainHeadline}
            </h1>
            <p className="paragraph paragraph__text-center margin-bottom-24">
              {mainSubline}
            </p>
          </div>
          <div className="box">
            <div className="box__elem">
              <div className="margin-bottom-33 wrapper flex-column">
                <h2 className="heading heading-2 margin-top-16">Schritt 1</h2>
                <p className="paragraph paragraph__text-center">{textStep1}</p>
                <StoreButtons />
              </div>
              <div className="margin-bottom-33 wrapper flex-column">
                <h2 className="heading heading-2">Schritt 2</h2>
                <p className="paragraph paragraph__text-center padding-bottom-15">
                  {textStep2}
                </p>
                {hasPurchaseToken && (
                  <a
                    href={`schlafen://purchase/connect/${purchase?.purchaseToken}`}
                    className="link__cta"
                    title="App mit Freischaltlink öffnen"
                  >
                    Freischaltlink öffnen
                  </a>
                )}
                {hasVoucherToken && (
                  <a
                    href={`schlafen://purchase/connect/${redeemVoucher?.token}`}
                    className="link__cta"
                    title="App mit Gutscheincode öffnen"
                  >
                    Freischaltlink öffnen
                  </a>
                )}
              </div>
              <div className="wrapper flex-column">
                <h2 className="heading heading-2">Fertig!</h2>
                <p className="paragraph paragraph__text-center padding-bottom-15">
                  {textStep3}{' '}
                  {email ? (
                    <a href={`mailto:${email}`} className="link__markdown" title={`E-Mail senden an: ${email}`}>
                      {email}
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default HowToContinueMobile;
