import { IUserState } from 'Redux/Slices/User/UserSlice';
import { Draft, PayloadAction } from '@reduxjs/toolkit';

export const registerUserWithOnetimePasswordRequest = (state: Draft<IUserState>, { type }: PayloadAction<{ name?: string, email: string }>) => {
  state.openRequests = [ ...state.openRequests, type ];
  state.failedRequests = state.failedRequests.filter((actionType) => actionType !== 'user/registerUserWithOnetimePasswordFailure');
  state.updatedAt = Date.now();
};

export const registerUserWithOnetimePasswordSuccess = (state: Draft<IUserState>) => {
  state.updatedAt = Date.now();
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'user/registerUserWithOnetimePasswordRequest');
};

export const registerUserWithOnetimePasswordFailure = (state: Draft<IUserState>, { type, payload }: PayloadAction<{ error: any }>) => {
  state.updatedAt = Date.now();
  state.failedRequests = [ ...state.failedRequests, type ];
  state.openRequests = state.openRequests.filter((actionType) => actionType !== 'user/registerUserWithOnetimePasswordRequest');
};
