import styled from 'styled-components';
import { Section } from 'View/Container/index';
import { asRem } from 'utils/asRem';
import { breakpoints } from 'View/Theme';

export default styled(Section).attrs({
  type: 'full-width'
}) `
  
  &.mime-elements-section {
    text-align: center;
    padding: 0 ${asRem(30)} ${asRem(16)};

    @media (min-width: ${breakpoints.md}) {
      padding: 0 ${asRem(100)} ${asRem(16)};
    }

    .header-image {
      width: ${asRem(250)};
      margin-top: ${asRem(48)};
    }

    .mime-elements-paragraph {
      margin-top: 0;
      margin-bottom: ${asRem(24)};

      @media (min-width: ${breakpoints.md}) {
        margin-bottom: ${asRem(40)};
      }
    }

    .logo-background {
      display: none;

      @media (min-width: ${breakpoints.lg}) {
        display: block;
        position: absolute;
        bottom: ${asRem(-200)};
        right: 0;
        opacity: 0.5;
      }

      @media (min-width: ${breakpoints.lg}) {
        display: block;
        position: absolute;
        bottom: ${asRem(-200)};
        right: ${asRem(-100)};
        opacity: 0.5;
      }
    }

    .brand-logo-wrapper {
      margin: ${asRem(-40)} auto 0;
      width: ${asRem(250)};
      height: ${asRem(100)};

      @media (min-width: ${breakpoints.md}) {
        margin: ${asRem(-60)} ${asRem(-100)} 0 auto;
      }
    }

    @media (min-width: ${breakpoints.md}) {
      bottom: 0;
    }
  }
`;
