import React from 'react';

export const HintBox = () => {
  return (
    <div className="box__rounded--dark-blue">
      <p className="icon-box__paragraph-additional ta-center">
        7Schläfer ist in der gesamten DACH-Region auf iOS und Android erhältlich. Ausgenommen ist
        aktuell der Google PlayStore in der Schweiz.&nbsp;
        <span className="em">Viel Spaß beim Herunterladen und endlich besser Schlafen.</span>
      </p>
    </div>
  );
};

