import { FormikErrors, FormikValues } from 'formik';

type Errors = {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  zipCode?: string;
  city?: string;
  email?: string;
};

const Validation = (
  values: FormikValues
): FormikErrors<FormikValues | undefined> => {
  const errors: Errors = {};

  if (!values.firstName) {
    errors.firstName = 'Vorname wird benötigt';
  }

  if (!values.lastName) {
    errors.lastName = 'Name wird benötigt';
  }

  if (!values.addressLine1) {
    errors.addressLine1 = 'Straße und Hausnummer werden benötigt';
  }

  if (!values.zipCode) {
    errors.zipCode = 'PLZ wird benötigt';
  } else {
    if (values.zipCode.length >= 6) {
      errors.zipCode = 'Die PLZ ist ungültig.';
    }
    if (values.zipCode.length <= 4) {
      errors.zipCode =
        'Produkte von 7Schläfer können nur aus Deutschland gekauft werden. Bitte gib eine gültige PLZ an.';
    }
  }

  if (!values.city) {
    errors.city = 'Ort wird benötigt';
  }

  if (!values.email) {
    errors.email = 'E-Mail wird benötigt';
  }

  if (Object.values(errors).some((item) => item?.length)) {
    return errors;
  }

  return undefined;
};

export default Validation;
