export const insuranceFormRequiredFields: Record<string, unknown> = {
  name: 'Vor- und Nachname wird benötigt.',
  email: 'E-Mail wird benötigt.',
  insuranceNumber: 'Versichertennummer wird benötigt.',
  dateOfBirth: 'Geburtsdatum wird benötigt.',
  gender: 'Geschlecht wird benötigt.',
  age: 'Alter wird benötigt.',
  healthPreconditions: 'Bitte akzeptiere die Gesundheitsvoraussetzungen.',
  legalConditions: 'Bitte akzeptiere die AGB und Hinweise zum Datenschutz.',
  dataTransfer: 'Bitte akzeptiere die Datenweitergabe.',
  noMoreCourses: 'Bitte bestätige dein Präventionsbudget.',
  successfulCourse: 'Bitte bestätige, dies zur Kenntniss genommen zu haben.',
};
