import Bugsnag from '@bugsnag/js';
import { environment } from './environment';

export function logError(error: Error) {
  if (environment === 'development') {
    console.error(error);
  } else {
    Bugsnag.notify(error);
  }
}
