import { applicationSlice } from 'Redux/Slices/Application/ApplicationSlice';
import { insuranceCooperationSlice } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';
import { userSlice } from 'Redux/Slices/User/UserSlice';

const Actions = {
  ...applicationSlice.actions,
  ...insuranceCooperationSlice.actions,
  ...userSlice.actions,
};

export type TActions = typeof Actions;

const failureActionKeys = Object.keys(Actions).filter((actionType: string) => !!actionType.match(/Failure/));

export const FailureActions = {};
failureActionKeys.forEach((actionType: string) => {
  // @ts-ignore
  FailureActions[actionType] = Actions[actionType];
});

export const errorActionConstants = Object.values(FailureActions).map((action: any) => action.type);

export default Actions;
