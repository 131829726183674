import { RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect } from 'react';
import Header from 'components/Header';
import { Store } from 'utils/store';
import moment from 'moment';

import DownloadIcn from 'img/terminate/offline-download.svg';

const TerminateSuccess: React.FC<RouteComponentProps> = (props) => {
  const [store] = useContext(Store);
  const { path } = props;
  const { terminateDetails } = store;

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <>
      <Header path={path} />
      <main className="main terminate-success">
        <div className="wrapper flex-column padding-top-100 margin-bottom-50">
          <h1 className="heading heading-1 margin-bottom-8">Kündigung erhalten</h1>

          <div className="box box__rounded">
            <div className="box__elem">
              <div className="margin-bottom-33 wrapper__box flex-column">
                <p className="paragraph paragraph__text-center margin-bottom-24">
                  Wir haben deinen Kündigungswunsch am {moment(terminateDetails?.receivedDate).format('DD.MM.YYYY HH:mm')} Uhr erhalten und
                  bestätigen dir den Eingang. Wir kümmern uns umgehend darum, ihn zu bearbeiten. Du kannst deine Eingangs-Bestätigung hier
                  herunterladen - wir haben sie dir zudem per E-Mail an <strong>{terminateDetails?.email}</strong> geschickt.
                  <br />
                  Solltest du Rückfragen haben, erreichst du unseren Kundensupport{' '}
                  <strong>
                    {' '}
                    <a
                      href="mailto:info@7schlaefer.app"
                      className="btn-terminate-abo-success-mail-to"
                      title="E-Mail senden an: info@7schlaefer.app"
                    >
                      info@7schlaefer.app
                    </a>
                  </strong>
                  .{' '}
                </p>
              </div>
              <div className="wrapper flex-column">
                <a
                  className="link__cta cta-pdf btn-download-pdf"
                  href={terminateDetails?.confirmationPdfUrl}
                  title="Öffnet die Eingangsbestätigungs PDF"
                >
                  <img className="btn-download-pdf" src={DownloadIcn} alt="download" />
                  <span className="btn-download-pdf">Eingangsbestätigung herunterladen</span>
                </a>
              </div>
            </div>
          </div>
          <div className="imprint__image-freddy-laeuft">
            <span className="visuallyhidden">Bild: 7Schläfer Freddy läuft</span>
          </div>
        </div>
      </main>
    </>
  );
};

export default TerminateSuccess;
