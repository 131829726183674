
 import { StaticPageName, useStaticPagesQuery } from 'api/graphql';
import MarkdownRenderer from 'components/MarkdownRenderer';
import React, { FunctionComponent } from 'react';
import { Bold } from 'View/Common/Bold/Bold';
import { Modal } from 'View/Component';

interface IProps {
  open?: boolean;
  onClose?: () => void;
  pageName: StaticPageName;
}

export const MarkdownModal: FunctionComponent<IProps> = ({ open, onClose, pageName }) => {
  const { data, loading, error } = useStaticPagesQuery({
    variables: { name: pageName },
    onError: (error) => {
      console.error('GraphQL Error:', error);
    },
  });

  const markdownData = data?.staticPages?.[0]?.markdown;

  let errorMessage = 'Fehler beim Laden der Seite. Bitte versuchen Sie es später erneut.';
  if (error?.message) {
    errorMessage = `Fehler: ${error.message}. Bitte versuchen Sie es später erneut.`;
  }

  return (
    <Modal onClose={onClose} open={open}>
      {markdownData && <MarkdownRenderer data={markdownData} inModal />}
      {loading && <Bold className="paragraph ta-left">Loading...</Bold>}
      {error && <Bold className="paragraph ta-left">{errorMessage}</Bold>}
    </Modal>
  );
};
