import { TEpic } from 'Redux/Config';
import Actions from 'Redux/Actions';
import { EMPTY, mergeMap } from 'rxjs';
import { ofType } from 'redux-observable';

export const applicationBootstrappedEpic: TEpic = (action$) => action$.pipe(
    ofType(Actions.applicationBootstrapped.type),
    mergeMap(() => {

        return EMPTY;
    })
);
