import React from 'react';

const Benefits = () => {
  return (
    <section className="benefits__section">
      <div className="wrapper__flex-col-row flex-basis-100">
        <div className="wrapper__flex-col flex-basis-50 padding-r-0-30">
          <h2 className="heading heading-2-b2b ta-left">
            Entdecke, wie guter Schlaf dein Leben verändert.
          </h2>
          <p className="paragraph">
            7Schläfer bietet Unterstützung beim Ein-, Aus- und Durchschlafen. Die App basiert auf
            den neuesten Erkenntnissen der Wissenschaft. In 7 Wochen Audiokurs macht Schlafforscher
            und Biologe Dr. Albrecht Vorster die Teilnehmenden zu Schlafexperten in eigener Sache.
            7Schläfer beinhaltet neben dem Audiokurs eine umfangreiche Bibliothek an Schlafhilfen,
            sowie ein persönliches Schlaf-Logbuch.
          </p>
          <ul className="list">
            <li className="list__item-checkmark">
              7 Wochen Schlaf-Kurs mit Trainings zur Verbesserung des eigenen Schlafes
            </li>
            <li className="list__item-checkmark">
              Abend- und Morgenroutinen für einen neuen Rhythmus
            </li>
            <li className="list__item-checkmark">Umfangreiche Einschlaf-Bibliothek</li>
            <li className="list__item-checkmark">Dein individuelles Schlaf-Logbuch</li>
          </ul>
        </div>
        <div className="wrapper__flex-col flex-basis-50">
          <div className="benefits__image-app-screens">
            <span className="visuallyhidden">Bild: App Screens</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
