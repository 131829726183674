import { Link, RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { ROUTES } from 'utils/typings/routes';
import { Menu } from '../View/Component';

interface Props extends RouteComponentProps {
  noNavigation?: boolean;
}

// @deprecated use Menu instead
const Header: React.FC<Props> = (props) => {
  const { noNavigation } = props;
  return (
    <Menu noNavigation={noNavigation} />
  );
};

export default Header;
