export const colors = {
  beige: {
    'c-10': '#f9f7f2',
    c000: '#f3f0e5',
    c010: '#f0ead5',
    c020: '#ece3c4',
    c030: '#e5dab4',
    c040: '#dccea3',
    c050: '#cffc93',
    c060: '#beae83',
    c070: '#aa9971',
    c080: '#90805e',
    c090: '#74664a',
    c100: '#574b37',
    c110: '#3e362a'
  },
  red: {
    c000: '#f2e3d3',
    c010: '#f4c69d',
    c020: '#f4ae7c',
    c030: '#f19967',
    c040: '#eb8457',
    c050: '#e06f4c',
    c060: '#c5603e',
    c070: '#a55031',
    c080: '#803d25',
    c090: '#562918',
    c100: '#2b150c'
  },
  blue: {
    c000: '#deeeff',
    c010: '#a7c4e6',
    c020: '#7699cb',
    c030: '#4e72b0',
    c040: '#305299',
    c050: '#2a4578',
    c060: '#243a5e',
    c070: '#1f3048',
    c080: '#1b2737',
    c090: '#17202a',
    c100: '#12181f'
  },
  error: {
    c050: '#f4ae7c',
    c100: '#ee4738'
  },
  success: {
    c050: '#6ea968',
    c100: '#5b8357'
  },
  logo: {
    blue: '#587db4',
    beige: '#f0ead5',
    darkBlue: '#1b2737'
  },
  grey: {
    c200: '#ededed',
    c500: '#717171',
  },

  deprecated: {
    white: {
      c100: '#ffffff',
      c500: '#f3f0e4'
    },
    grey: {
      c600: '#6b6b6b'
    }
  }


};
