import styled from 'styled-components';
import { asRem } from 'utils/asRem';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import { colors } from 'utils/colors';

export default styled.div`
  padding-top: ${asRem(40)};
  width: 100%;
  background-color: transparent;
  margin-bottom: var(--sleep-gutter-xs);

  ${mediaQueries.md} {
    padding-top: var(--sleep-gutter-xl);
    border-radius: ${asRem(10)};
    background-color: var(--sleep-blue-c770);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: var(--sleep-gutter-xl);
  }

  .insurance-form {
    margin-top: var(--sleep-gutter-l);
    padding-top: ${asRem(40)};
    padding-bottom: 0;
    text-align: left;
    display: inline-block;

    ${mediaQueries.md} {
      margin: 0 auto;
      overflow: hidden;
    }
  }

  .otp-view {
    #user-with-otp__form {
      flex-direction: column;
      display: flex;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .otp-view,
  .error-view,
  .success-view {
    padding: ${asRem(40)};
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    ${mediaQueries.md} {
      width: 100%;
      border-radius: ${asRem(10)};
      padding-left: ${asRem(48)};
      padding-right: ${asRem(48)};
    }

    p {
      max-width: ${asRem(600)};
    }
  }

  .success-view {
    padding-bottom: 0;

    .link__store-btn-wrapper .link__store-btn {
      width: ${asRem(150)};
      height: ${asRem(40)};
    }

    .mime-elements-image {
      width: 100%;
      margin-top: ${asRem(24)};
      ${mediaQueries.md} {
        width: ${asRem(720)};
      }
    }
  }
`;
