import { FormikErrors, FormikValues } from 'formik';
import { Cancellation } from 'api/graphql';

type Errors = {
  cancellationType?: string;
  product?: string;
  cancellationReason?: string;
  email?: string;
};

const Validation = (
  values: FormikValues
): FormikErrors<FormikValues | undefined> => {
  const errors: Errors = {};

  if (!values.product) {
    errors.product = 'Bitte wähle aus, welches Abo du kündigen möchtest.';
  }

  if (!values.email) {
    errors.email = 'Bitte gib eine gültige E-Mail Adresse an.';
  }

  if (
    values.cancellationType === Cancellation.Reasoned &&
    values.cancellationReason.trim().length === 0
  ) {
    errors.cancellationReason =
      'Bitte gib an, aus welchem wichtigen Grund du außerordentlich kündigen möchtest, damit wir deine Anfrage bearbeiten können.';
  }

  if (Object.values(errors).some((item) => item?.length)) {
    return errors;
  }

  return undefined;
};

export default Validation;
