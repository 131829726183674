import React from 'react';
import { StyledHomeSection } from './HomeSection.style';
const Section_2 = () => {
  return (
    <StyledHomeSection>
      <div className="wrapper">
        <section className="home__section-2">
          <h2 className="heading  heading-2">Mit 7Schläfer besser schlafen – so funktioniert’s:</h2>
          <div className="article__wrapper">
            <article className="article">
              <div className="article__image article__image--einschlafen">
                <span className="visuallyhidden">Illustration: Einschlafen</span>
              </div>
              <div className="article__content__wrapper">
                <h3 className="heading heading-3">Einschlafen</h3>
                <p className="paragraph">
                  Finde entspannt in den Schlaf – mit Schlafhilfen vom Experten. Entdecke Einschlafgeschichten, Meditationen und mehr. Das
                  Beste daran: Die Sammlung wächst stetig!
                </p>
              </div>
            </article>
            <article className="article">
              <div className="article__image article__image--verstehen">
                <span className="visuallyhidden">Illustration: Verstehen</span>
              </div>
              <div className="article__content__wrapper">
                <h3 className="heading heading-3">Verstehen</h3>
                <p className="paragraph">
                  Was steckt wirklich hinter gutem Schlaf? Spannendes Wissen, aber auch praktische Tipps und Trainings erwarten dich im
                  Audiokurs mit Schlafforscher Albrecht Vorster.
                </p>
              </div>
            </article>
            <article className="article">
              <div className="article__image article__image--tracken">
                <span className="visuallyhidden">Illustration: Tracken</span>
              </div>
              <div className="article__content__wrapper">
                <h3 className="heading heading-3">Tracken</h3>
                <p className="paragraph">
                  Mit 7Schläfer verschaffst du dir einen Überblick über deine Nächte. In deinem Logbuch dokumentierst du Schlafzeiten und
                  Stimmung. Hängt das Eine mit dem Anderen zusammen? Gemeinsam finden wir es heraus!
                </p>
              </div>
            </article>
            <article className="article">
              <div className="article__image article__image--verankern">
                <span className="visuallyhidden">Illustration: Verankern</span>
              </div>
              <div className="article__content__wrapper">
                <h3 className="heading heading-3">Verankern</h3>
                <p className="paragraph">
                  Mach guten Schlaf zur Gewohnheit. Mit 7Schläfer findest du deine persönliche Routine, die dich zuverlässig erholsam
                  schlafen lässt. Tipps und Trainings aus dem Audiokurs unterstützen dich dabei.
                </p>
              </div>
            </article>
          </div>
        </section>
      </div>
    </StyledHomeSection>
  );
};

export default Section_2;
