import React, { FunctionComponent } from 'react';
import Style from './Card.style';
import { CardProps } from 'antd';

export const Card: FunctionComponent<CardProps> = ({ children , ...props }) => {
  return (
    <Style {...props}>
      { children }
    </Style>
  );
};
