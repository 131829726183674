import { Button } from 'View/Common';
import React, { useState, MouseEvent, useMemo } from 'react';
import { MarkdownModal } from 'View/Component/Modals';
import { StaticPageName } from 'api/graphql';

export const LegalConditionLabel = () => {

  const [showModal, setShowModal] = useState<string|false>(false);

  const handleOnClick = (modal: string) => (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();

    setShowModal(modal);
  };

  const pageName = useMemo(() => {
    switch(showModal) {
      case 'showTerms': return StaticPageName.TermsAndConditions;
      case 'showPrivacy': return StaticPageName.PrivacyPolicy;
      // no-default
    }
    return StaticPageName.PrivacyPolicy;
  }, [showModal]);

  return (
    <>
      <MarkdownModal open={!!showModal} onClose={() => setShowModal(false)} pageName={pageName} />

      Ich akzeptiere die{' '}
      <Button
        title="öffnet ein Fenster mit den AGBs"
        type="link"
        className="form-regulatory-link"
        onClick={handleOnClick('showTerms')}
      >
        AGB
      </Button>{' '}
      und die Hinweise zum{' '}
      <Button
        title="öffnet ein Fenster mit den Datenschutzbedingungen"
        type="link"
        className="form-regulatory-link"
        onClick={handleOnClick('showPrivacy')}
      >
        Datenschutz
      </Button>
      .
    </>
  );
};
