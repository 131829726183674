import type { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { IInsuranceCooperationState } from 'Redux/Slices/InsuranceCooperation/InsuranceCooperationSlice';
import { userSlice } from 'Redux/Slices/User/UserSlice';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container/InsuranceContainer/InsuranceContainerContext';

export const showOtpScreen = (builder: ActionReducerMapBuilder<IInsuranceCooperationState>) => {
  builder.addCase(userSlice.actions.registerUserWithOnetimePasswordSuccess, (state) => {
    state.showFormScreen = INSURANCE_CONTAINER_VIEW.OTP;
  });
};
