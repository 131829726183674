import React, { FC, FormEvent, MouseEvent, useContext, useState } from 'react';
import { InsuranceContainerContext } from 'View/Container/InsuranceContainer/InsuranceContainerContext';
import { INSURANCE_CONTAINER_VIEW } from 'View/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceFormData, isOtpFailed } from 'Redux/Queries';
import { Form, Button, Link, Span, CodeInput, Paragraph, Headline } from 'View/Common';
import Actions from 'Redux/Actions';

export type TOtpViewProps = {
  children?: React.ReactNode;
  className?: string;
};

export const OtpView: FC<TOtpViewProps> = ({ children, className = '' }) => {

  const dispatch = useDispatch();

  const { activeView, b2bClientKey } = useContext(InsuranceContainerContext);
  const insuranceFormData = useSelector(getInsuranceFormData);
  const loginError = useSelector(isOtpFailed);

  const [token, setToken] = useState('');
  const isLoading = false;

  const handleOnSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (isLoading) {
      return;
    }

    return dispatch(Actions.attendInsuranceCooperationUserDataRequest({ token }));
  };

  const handleOnSendNewToken = (evt: MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    dispatch(Actions.resendOnetimePasswordRequest());
  };


  if(activeView !== INSURANCE_CONTAINER_VIEW.OTP) {
    return null;
  }

  return (
    <div className="otp-view" id="sign-in-user-with-otp">
      <div className="form-header">
        <Headline level={3}>E-Mail Registrierung bestätigen</Headline>
        <Paragraph>
          Gib hier bitte den 6-stelligen Code ein, den du per E-Mail bekommen hast. Er wurde an  {insuranceFormData?.email} verschickt.
        </Paragraph>
      </div>
      <Form className="form" onSubmit={handleOnSubmit} id="user-with-otp__form">
        <CodeInput value={token} onChange={setToken} error={loginError}/>
        {loginError && <Span className="extra-small fb-error">Code ist nicht gültig</Span>}
        <Button className="mt-4" htmlType="submit" disabled={token.length < 6} id="sign-in-user-otp-btn">
          {isLoading ? 'Code wird überprüft ...' : 'Code abschicken'}
        </Button>
      </Form>
      <div className="resend-code-wrapper">
        <Paragraph className="small-bold text-center mt-4">
          <Link className="text-uppercase" onClick={handleOnSendNewToken}>
            Neuen Code anfordern
          </Link>
        </Paragraph>
      </div>
    </div>
  );
};
