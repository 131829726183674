import styled from 'styled-components';
import { mediaQueries } from 'utils/Constants/Breakpoints';
import bgImageMobile from 'img/bgr_waves_mobile.svg';
import bgImage from 'img/bgr_waves.svg';
import { breakpoints, colors } from 'View/Theme';
import { asRem } from 'utils/asRem';

export default styled.main.attrs({ className: 'trust-portal' })`
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden !important;
  background-color: blue;

  ${mediaQueries.md} {
    background-image: url(${bgImage});
  }

  .header-section {
    h2 {
      margin-bottom: ${asRem(16)};
    }
    .container-fluid div {
      padding-bottom: ${asRem(24)};
    }
  }

  .mime-elements-section {
    .container {
      max-width: ${asRem(1100)};
    }

    &[type='full-width'] .container {
      max-width: 100%;
    }
  }

  .signet-section {
    ${mediaQueries.md} {
      margin-bottom: ${asRem(30)};
    }

    h2 {
      margin-bottom: ${asRem(16)};
    }

    .mime-elements-rounded-box {
      text-align: center;
      align-items: center;
      padding: ${asRem(24)} ${asRem(30)};

      ${mediaQueries.md} {
        padding: ${asRem(40)};
      }
    }

    .signet-box {
      padding: 0 ${asRem(42)};
      flex: 0 0 100%;
      margin: 0 0 ${asRem(30)} 0;

      span {
        margin: ${asRem(12)} 0 0;
      }

      ${mediaQueries.md} {
        flex: 0 0 33.333%;
        margin: ${asRem(24)} 0 0;
      }

      .mime-elements-paragraph {
        margin: ${asRem(12)} 0 0;
      }

      .download-icon {
        width: ${asRem(25)};
        height: ${asRem(25)};
        margin: 0 ${asRem(3)} ${asRem(3)};
      }
    }
  }

  .description-section {
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: ${asRem(40)};
    }

    h2 {
      margin-bottom: ${asRem(40)};
    }
    .description-image-wrapper {
      width: 100%;
      flex-direction: column-reverse;
      display: flex;

      @media (min-width: ${breakpoints.md}) {
        padding-top: 0;
        flex-direction: row-reverse;
      }

      .description-image {
        width: ${asRem(343)};
        height: ${asRem(343)};
        align-self: center;
        margin-top: ${asRem(-50)};

        @media (min-width: ${breakpoints.md}) {
          align-self: flex-start;
          width: ${asRem(218)};
          height: ${asRem(218)};
          margin-left: ${asRem(20)};
          margin-top: ${asRem(50)};
        }

        @media (min-width: ${breakpoints.lg}) {
          margin-left: ${asRem(50)};
          margin-top: 0;
          width: ${asRem(304)};
          height: ${asRem(304)};
        }
      }
    }

    .main-list {
      .list-icon {
        width: ${asRem(50)};
        margin-right: ${asRem(16)};
        align-self: center;
        margin-bottom: ${asRem(-8)};

        @media (min-width: ${breakpoints.md}) {
          margin-bottom: ${asRem(8)};
        }
      }
    }

    .description-list {
      margin-bottom: ${asRem(40)};
      padding-right: ${asRem(24)};
      .mime-elements-paragraph {
        margin: 0;
      }
      .mime-elements-list-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: ${asRem(20)};
      }
      .icon {
        align-self: flex-start !important;
        width: ${asRem(40)};
        height: ${asRem(40)};
        margin-right: ${asRem(30)};

        @media (min-width: ${breakpoints.md}) {
          margin-bottom: ${asRem(8)};
        }
      }
    }
  }

  .contact-section {
    @media (min-width: ${breakpoints.lg}) {
      margin-top: ${asRem(40)};
    }

    .left-col {
      margin-bottom: ${asRem(40)};

      @media (min-width: ${breakpoints.md}) {
        margin-bottom: 0;
        padding-right: ${asRem(30)};
      }
      @media (min-width: ${breakpoints.lg}) {
        padding-right: ${asRem(50)};
      }
    }

    .left-col .mime-elements-paragraph {
      margin: ${asRem(8)} 0 0;

      @media (min-width: ${breakpoints.md}) {
        margin: ${asRem(16)} 0 0;
      }
    }

    .contact-card {
      text-align: center !important;
      align-items: center !important;

      @media (min-width: ${breakpoints.md}) {
        padding: ${asRem(60)} ${asRem(40)} !important;
      }

      .mime-elements-paragraph {
        margin: ${asRem(24)} 0 0;

        @media (min-width: ${breakpoints.md}) {
          margin: ${asRem(30)} 0 0;
        }
      }

      .mime-elements-button {
        margin-top: ${asRem(24)};

        @media (min-width: ${breakpoints.md}) {
          margin-top: ${asRem(50)};
        }
      }
    }
  }

  footer {
    width: 100%;
    height: ${asRem(10)};
  }
`;
