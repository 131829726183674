import { ParamType } from 'utils/typings/urlParamType';

const URLParam = (location: Location, type: ParamType): string => {
  const search = location.search;

  // return empty strings for
  // type consistency
  if (!search) {
    return '';
  }

  // remove question mark from query string
  // and extract all other parts
  // separated by '&' into an array
  const sanitized = search.split('?')[1].split('&');

  const regex =
    type === ParamType.CODE ? /^code=/g : ParamType.EMAIL ? /^email=/g : null;

  let targetIndex = -1;

  if (regex) {
    sanitized.forEach((item, index) => {
      if (regex.exec(item)) {
        targetIndex = index;
      }
    });
  }

  // return empty string if no matching parameter is
  // present in any of the array's items
  if (targetIndex === -1) {
    return '';
  }

  // extract value of the query parameter
  const targetElement = sanitized[targetIndex];
  const value = targetElement.split('=')[1];

  return value;
};

export default URLParam;
