import { useContext, useState } from 'react';
import { Store } from 'utils/store';
import { OfferProvider, SinglePaymentOffer, SubscriptionOffer, useOffersQuery } from '../../api/graphql';
import { ACTION_TYPES } from '../../utils/typings/actionTypes';

export const useGetOffers = () => {

  const [store, dispatch] = useContext(Store);
  const [offers, setOffers] = useState<{
    zpp: SubscriptionOffer | null;
    subscriptions: (SinglePaymentOffer | SubscriptionOffer)[];
  }>({ zpp: null, subscriptions: []});

  const enteredCouponCode = store?.enteredCouponCode ?? '';

  const { loading, error, refetch } = useOffersQuery({
    variables: {
      provider: OfferProvider.Stripe,
      couponCode: enteredCouponCode
    },
    onCompleted: (data) => {
      // @ts-ignore
      //uuid for coupon has an error (undefined method `id' for #<Hash:0x00007fd81154bc30>)
      dispatch({ type: ACTION_TYPES.UPDATE_OFFERS, offers: data.offers });
      const zppPlan = data.offers.find((offer) => offer.zppCertified);

      setOffers({
        zpp: zppPlan ? (zppPlan as unknown as SubscriptionOffer) : null,
        subscriptions: data.offers.filter((offer) => !offer.zppCertified) as (SinglePaymentOffer | SubscriptionOffer)[]
      });
    }
  });

  return {
    couponCode: enteredCouponCode,
    loading,
    error,
    refetch,
    offers
  };

};
