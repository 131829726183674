import { navigate, RouteComponentProps } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect } from 'react';
import Header from 'components/Header';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';

import Validation from './Validation';
import ErrorFocus from 'utils/Formik_Error_Focus';
import FormikContextHelper from 'utils/Formik_Context_Helper';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import Subheader from 'views/Payment/SubHeader';

const BillingDetails: React.FC<RouteComponentProps> = (props) => {
  const [store, dispatch] = useContext(Store);
  const { path } = props;
  const { billingDetails, selectedOffer } = store;
  useEffect(() => {
    if (!selectedOffer) {
      navigate(ROUTES.PRICING);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [selectedOffer]);

  return (
    <>
      <Header path={path} noNavigation />
      <main className="main main--no-footer-cta">
        <Subheader path={path} />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: billingDetails?.firstName ?? '',
            lastName: billingDetails?.lastName ?? '',
            email: billingDetails?.email ?? '',
            city: billingDetails?.city ?? '',
            addressLine1: billingDetails?.addressLine1 ?? '',
            zipCode: billingDetails?.zipCode ?? '',
            country: billingDetails?.country ?? 'DE',
          }}
          onSubmit={(values) => {
            dispatch({
              type: ACTION_TYPES.UPDATE_BILLING_DETAILS,
              billingDetails: values,
            });
            dispatch({
              type: ACTION_TYPES.UPDATE_IS_BILLING_DETAILS_DIRTY,
              isBillingDetailsDirty: false,
            });

            navigate(ROUTES.PAYMENT_METHOD);
          }}
          validate={Validation}
        >
          {({ errors, isSubmitting, isValidating, handleChange, values, dirty }) => (
            <Form className="billing__form">
              <div className="billing__wrapper-lg-w50">
                <div className="billing__input-wrapper">
                  <label className="billing__label" htmlFor="firstName">
                    Vorname
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="Ina"
                    className={`billing__input ${errors.firstName ? 'billing__input--error' : ''}`}
                    onChange={handleChange}
                    value={values.firstName}
                  />
                  {errors && <p className="billing__error-message">{errors.firstName}</p>}
                </div>
                <div className="billing__input-wrapper">
                  <label className="billing__label" htmlFor="lastName">
                    Name
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    placeholder="Musterfrau"
                    className={`billing__input ${errors.lastName ? 'billing__input--error' : ''}`}
                    onChange={handleChange}
                    value={values.lastName}
                  />
                  {errors && <p className="billing__error-message">{errors.lastName}</p>}
                </div>
                <div className="billing__input-wrapper">
                  <label className="billing__label" htmlFor="addressLine1">
                    Straße und Hausnummer
                  </label>
                  <Field
                    id="addressLine1"
                    name="addressLine1"
                    placeholder="Musterstraße 3"
                    className={`billing__input ${errors.addressLine1 ? 'billing__input--error' : ''}`}
                    onChange={handleChange}
                    value={values.addressLine1}
                  />
                  {errors && <p className="billing__error-message">{errors.addressLine1}</p>}
                </div>
                <div className="billing__wrapper-address">
                  <div className="billing__input-wrapper billing__input-wrapper--zip-code">
                    <label className="billing__label" htmlFor="zipCode">
                      Postleitzahl
                    </label>
                    <Field
                      id="zipCode"
                      name="zipCode"
                      placeholder="12345"
                      className={`billing__input ${errors.zipCode ? 'billing__input--error' : ''}`}
                      onChange={handleChange}
                      value={values.zipCode}
                    />
                    {errors && <p className="billing__error-message">{errors.zipCode}</p>}
                  </div>
                  <div className="billing__input-wrapper billing__input-wrapper--city">
                    <label className="billing__label" htmlFor="city">
                      Ort
                    </label>
                    <Field
                      id="city"
                      name="city"
                      placeholder="Musterstadt"
                      className={`billing__input ${errors.city ? 'billing__input--error' : ''}`}
                      onChange={handleChange}
                      value={values.city}
                    />
                    {errors && <p className="billing__error-message">{errors.city}</p>}
                  </div>
                </div>
              </div>
              <div className="billing__wrapper-lg-w50">
                <div className="billing__input-wrapper">
                  <label className="billing__label" htmlFor="country">
                    Land
                  </label>
                  <select className="billing__input" id="country" name="country" defaultValue="DE" onChange={handleChange}>
                    <option value="land" disabled>
                      -- Land --
                    </option>
                    <option value="DE">Deutschland</option>
                  </select>
                  {/* keep the empty error message element around for horizontal alignment */}
                  {errors && <p className="billing__error-message"></p>}
                </div>
                <div className="billing__input-wrapper">
                  <label className="billing__label" htmlFor="email">
                    E-Mail-Adresse *
                  </label>
                  <Field
                    id="email"
                    name="email"
                    placeholder="ina@beispiel.com"
                    className={`billing__input ${errors.email ? 'billing__input--error' : ''}`}
                    onChange={handleChange}
                    value={values.email}
                    type="email"
                  />
                  <p className="billing__paragraph">
                    *Falls du bereits einen 7Schläfer Account hast, nutze bitte hier die gleiche E-Mail Adresse
                  </p>
                  {errors && <p className="billing__error-message">{errors.email}</p>}
                </div>
                <button className="billing__btn--orange" type="submit" title="Weiter im Kaufprozess">
                  Weiter
                </button>
              </div>
              <ErrorFocus isSubmitting={isSubmitting} isValidating={isValidating} errors={errors} />
              <FormikContextHelper isDirty={dirty} />
            </Form>
          )}
        </Formik>
        <div className="billing__image-luna"></div>
      </main>
    </>
  );
};

export default BillingDetails;
