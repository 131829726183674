import { FormikErrors, FormikValues } from 'formik';
import { useEffect } from 'react';

interface Props {
  isValidating: boolean;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
}

const ErrorFocus = (props: Props) => {
  const { isSubmitting, isValidating, errors } = props;
  const offset = 150;
  useEffect(() => {
    if (!errors) {
      return;
    }
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement: HTMLElement | null = document.querySelector(
        `[name="${keys[0]}"]`
      );

      if (errorElement) {
        window.scroll({
          top: errorElement.offsetTop - offset,
          left: 0,
          behavior: 'smooth'
        });
        setTimeout(() => errorElement.focus(), 300);
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
};

export default ErrorFocus;
