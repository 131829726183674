import { Link, navigate, RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import {
  StaticPageName,
  StripeCardPaymentMethod,
  StripeSepaDebitPaymentMethod,
  usePurchaseStripeOfferMutation,
  useStripePaymentMethodLazyQuery,
} from 'api/graphql';
import Header from 'components/Header';
import { LoadingSpinner } from 'View/Common';
import { ACTION_TYPES } from 'utils/typings/actionTypes';
import calculateVat from 'utils/calculateVat';
import localizeAmount from 'utils/localizeAmount';
import { ROUTES } from 'utils/typings/routes';
import { Store } from 'utils/store';

import Subheader from 'views/Payment/SubHeader';
import Card from './Card';
import SepaDebit from './SepaDebit';
import { logError } from 'utils/logError';
import { MarkdownModal } from 'View/Component/Modals/MarkdownModal/MarkdownModal';

const Summary: React.FC<RouteComponentProps> = (props) => {
  const [store, dispatch] = useContext(Store);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const { path } = props;
  const { selectedOffer, billingDetails, stripeSetupIntent, enteredCouponCode } = store;
  const [getStripePaymentMethod, { data: stripePaymentMethodData, loading: stripePaymentMethodLoading }] =
    useStripePaymentMethodLazyQuery();

  const [purchaseStripeOffer, { loading: isPurchaseStripeOfferProcessing }] = usePurchaseStripeOfferMutation({
    onCompleted: (data) => {
      if (!data.purchaseStripeOffer) {
        logError(new Error('purchaseStripeOffer response data missing'));
        return;
      }
      const { purchaseToken, userAutoAssigned, isAlreadyPaying } = data.purchaseStripeOffer;
      dispatch({
        type: ACTION_TYPES.UPDATE_PURCHASE,
        purchase: { purchaseToken, userAutoAssigned },
      });

      if (isAlreadyPaying) {
        navigate(ROUTES.ALREADY_PAYING);
        return;
      }

      if (!userAutoAssigned) {
        navigate(ROUTES.CONFIRMATION_UNKNOWN);
      } else {
        navigate(ROUTES.CONFIRMATION);
      }
    },
    onError: (error) => {
      logError(error);
      navigate(ROUTES.PAYMENT_ERROR);
    },
  });

  useEffect(() => {
    if (!selectedOffer || !billingDetails || !stripeSetupIntent) {
      navigate(ROUTES.PRICING);
    }
    if (stripeSetupIntent?.payment_method) {
      const paymentMethodId = stripeSetupIntent.payment_method as string;
      getStripePaymentMethod({
        variables: { paymentMethodId },
      });
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [stripeSetupIntent, billingDetails, getStripePaymentMethod, selectedOffer]);

  if (!selectedOffer || !billingDetails) {
    return null;
  }
  return (
    <>
      <MarkdownModal open={showTerms} onClose={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions} />
      <MarkdownModal open={showPrivacy} onClose={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy} />
      <Header path={path} noNavigation />
      <main className="main main--no-footer-cta">
        <Subheader path={path} />
        <div className="summary summary__wrapper">
          <div className="summary__wrapper-content">
            <section className="summary__section">
              <h5 className="heading summary__heading">Gewählte Freischaltung:</h5>
              <div className="summary__line-wrapper">
                <div className="summary__label-offer">{selectedOffer.name}</div>
                <Link className="summary__link-edit" to={ROUTES.PRICING} title="zu den Angeboten" />
              </div>
              <div className="summary__line-wrapper">
                <div className="summary__label">Preis</div>
                <div className="summary__price">
                  {selectedOffer.crossedOutPrice ? selectedOffer.crossedOutPrice.localizedPrice : selectedOffer.price.localizedPrice}{' '}
                </div>
              </div>
              {selectedOffer.crossedOutPrice && (
                <div className="summary__line-wrapper" data-testid="e2e_summary_coupon">
                  <div className="summary__label-wrapper">
                    {selectedOffer.coupon && <div className="summary__label">{selectedOffer.coupon?.name}</div>}
                    <div className="summary__label">{enteredCouponCode}</div>
                  </div>
                  <div className="summary__price">
                    -{' '}
                    {selectedOffer.crossedOutPrice ? (
                      <>{localizeAmount((selectedOffer.crossedOutPrice.cents - selectedOffer.price.cents) / 100, false)} €</>
                    ) : (
                      selectedOffer.price.localizedPrice
                    )}{' '}
                  </div>
                </div>
              )}

              <div className="summary__line-wrapper">
                <div className="summary__label">inkl. {selectedOffer.taxRatePercentage} % MwSt.</div>
                <div className="summary__price">
                  {' '}
                  {selectedOffer.price.cents === 0
                    ? '0,00'
                    : localizeAmount(calculateVat(selectedOffer.price.cents, selectedOffer.taxRatePercentage), false)}{' '}
                  €
                </div>
              </div>
              <div className="summary__line-wrapper margin-top-16">
                <div className="summary__label summary__label-total summary__label-total">Gesamt</div>
                <div className="summary__price-total">{selectedOffer?.price.localizedPrice}</div>
              </div>
            </section>
            <section className="summary__section">
              <h5 className="summary__heading">E-Mail-Adresse:</h5>
              <div className="summary__line-wrapper">
                <div className="summary__email">{billingDetails?.email}</div>
                <Link className="summary__link-edit" to={ROUTES.BILLING_DETAILS} title="zu den Rechnungsdaten" />
              </div>
              <p className="summary__paragraph">
                An diese E-Mail senden wir den Freischalt-Link zu den Inhalten der App. Bitte stelle den Empfang der E-Mail sicher.
              </p>
            </section>
            <section className="summary__section">
              <div className="summary__line-wrapper">
                <h5 className="heading summary__heading">Rechnungsadresse:</h5>
                <Link className="summary__link-edit" to={ROUTES.BILLING_DETAILS} title="zu den Rechnungsdaten" />
              </div>
              <div className="summary__label">
                {billingDetails?.firstName} {billingDetails?.lastName}
              </div>
              <div className="summary__label">{billingDetails?.addressLine1}</div>
              <div className="summary__label">
                {billingDetails?.zipCode} {billingDetails?.city}
              </div>
              <div className="summary__label">{billingDetails?.country}</div>
            </section>
            <section className="summary__section">
              <div className="summary__line-wrapper">
                <div className="summary__heading">Zahlungsart:</div>
                <Link className="summary__link-edit" to={ROUTES.PAYMENT_METHOD} title="zur Zahlungsart" />
              </div>
              {stripePaymentMethodLoading && (
                <div className="loading-spinner__wrapper">
                  <LoadingSpinner />
                </div>
              )}
              {stripePaymentMethodData?.stripePaymentMethod?.__typename === 'StripeSepaDebitPaymentMethod' && (
                <SepaDebit data={stripePaymentMethodData.stripePaymentMethod as StripeSepaDebitPaymentMethod} />
              )}
              {stripePaymentMethodData?.stripePaymentMethod?.__typename === 'StripeCardPaymentMethod' && (
                <Card data={stripePaymentMethodData.stripePaymentMethod as StripeCardPaymentMethod} />
              )}
            </section>
            <p className="summary__paragraph" data-cy-name="terms-privacy-summary">
              Es gelten unsere{' '}
              <Link className="summary__link" onClick={() => setShowTerms(true)} to="" title="AGB">
                AGB
              </Link>{' '}
              und{' '}
              <Link className="summary__link" onClick={() => setShowPrivacy(true)} to="" title="Datenschutzbestimmungen">
                Datenschutzbestimmungen
              </Link>
              .
            </p>
          </div>
          <div className="summary__btn-wrapper">
            <button
              className="summary__btn--blue"
              type="button"
              onClick={() => window.history.back()}
              disabled={isPurchaseStripeOfferProcessing}
              title="Zurück zur letzten Seite"
            >
              Zurück
            </button>
            <button
              className="summary__btn--orange"
              data-testid="e2e_summary_complete_purchase"
              type="button"
              title="Kauf abschließen"
              onClick={() =>
                purchaseStripeOffer({
                  variables: {
                    offerId: selectedOffer.uuid,
                    paymentMethod: (stripeSetupIntent?.payment_method || '') as string,
                    firstName: billingDetails.firstName,
                    lastName: billingDetails.lastName,
                    email: billingDetails.email,
                    couponCode: enteredCouponCode,
                  },
                })
              }
              disabled={isPurchaseStripeOfferProcessing}
            >
              {isPurchaseStripeOfferProcessing ? <LoadingSpinner /> : 'Kauf abschließen'}
            </button>
          </div>
        </div>
        <div className="billing__summary-image-luna"></div>
      </main>
    </>
  );
};

export default Summary;
