import styled from 'styled-components';
import { Switch } from 'antd';
import { asRem } from 'utils/asRem';
import { colors } from 'utils/colors';

export default styled.div `
  display: inline-block;
  height: ${asRem(40)};
  padding: ${asRem(10)} ${asRem(16)};
  border-radius: ${asRem(20)} ${asRem(20)} ${asRem(20)} 0;
  background-color: ${colors.blue.c030};
  color: ${colors.deprecated.white.c100};
  font-family: "Source Sans Pro";
  font-size: ${asRem(16)};
  margin-bottom: ${asRem(20)};
  font-weight: 600;
`;
